import { useState } from 'react';
import { Accordion, Spinner, Col, Form, Row } from 'react-bootstrap';
import useBreakpoint from '../../../hooks/useBreakpoint';
import './index.scss';
import "react-datepicker/dist/react-datepicker.css";
import CustomButton from '../../../components/CustomButton';
import { faFloppyDisk, faPlus } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../../../components/IconButton';
import Select from '../../../components/Select';
import GET_FACTSHEET from '../../../graphql/queries/IIAs/getFactsheet';
import GET_ISCED_CODES from '../../../graphql/queries/IIAs/getISCEDCodes';
import { useMutation, useQuery } from '@apollo/client';
import UPDATE_FACTSHEET from '../../../graphql/mutations/IIAs/updateFactsheet';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import toastConfiguration from '../../../data/toastConfiguration';
import useUser from '../../../hooks/useUser';
import CustomModal from '../../../components/CustomModal';

const requirementsList = ["Academic requirements", "CV", "Motivational Letter", "Other"]

const EditFactsheet = () => {
    const [size] = useBreakpoint();
    const { user } = useUser();

    const [validated, setValidated] = useState(false);
    const [formInvalid, setFormInvalid] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [checkAccept, setCheckAccept] = useState(false);
    const [applicationsWinterDate, setApplicationsWinterDate] = useState("");
    const [applicationsSummerDate, setApplicationsSummerDate] = useState("");
    const [nominationsWinterDate, setNominationsWinterDate] = useState("");
    const [nominationsSummerDate, setNominationsSummerDate] = useState("");
    const [housingEmail, setHousingEmail] = useState("");
    const [housingPhone, setHousingPhone] = useState("");
    const [housingUrl, setHousingUrl] = useState("");
    const [visaEmail, setVisaEmail] = useState("");
    const [visaPhone, setVisaPhone] = useState("");
    const [visaUrl, setVisaUrl] = useState("");
    const [insuranceEmail, setInsuranceEmail] = useState("");
    const [insurancePhone, setInsurancePhone] = useState("");
    const [insuranceUrl, setInsuranceUrl] = useState("");
    const [applicationsContactEmail, setApplicationsContactEmail] = useState("");
    const [applicationsContactPhone, setApplicationsContactPhone] = useState("");
    const [applicationsContactUrl, setApplicationsContactUrl] = useState("");
    const [weeksLimitRequirements, setWeeksLimitRequirements] = useState("");
    const [weeksLimitTor, setWeeksLimitTor] = useState("");
    const [calendarTab, setCalendarTab] = useState(false);
    const [additionalReqTab, setAdditionalReqTab] = useState(false);
    const [inclusionTab, setInclusionTab] = useState(false);
    const [housingTab, setHousingTab] = useState(false);
    const [visaTab, setVisaTab] = useState(false);
    const [insuranceTab, setInsuranceTab] = useState(false);
    const [additionalInfoTab, setAdditionalInfoTab] = useState(false);
    const [additionalRequirementArray, setAdditionalRequirementArray] = useState([{
        requirement: "",
        details: "",
        ects: "",
        eqf_level: "",
        url: "",
        isced_f_code: "",
        isced_f_code_name: ""
    }]);
    const [servicesArray, setServicesArray] = useState([{
        name: "",
        description: "",
        email: "",
        phone: "",
        url: "",
    }]);
    const [infrastructureArray, setInfrastructureArray] = useState([{
        name: "",
        description: "",
        email: "",
        phone: "",
        url: ""
    }]);
    const [recognitionProcessArray, setRecognitionProcessArray] = useState([{
        email: "",
        phone: "",
        url: ""
    }]);
    const [otherInfoArray, setOtherInfoArray] = useState([{
        email: "",
        phone: "",
        url: "",
    }]);

    const [updateFactsheet, { loading: loadingUpdateFactsheet }] = useMutation(UPDATE_FACTSHEET);

    const { data: dataIsced, error: errorIsced, loading: loadingIsced } = useQuery(GET_ISCED_CODES);

    const { error, loading, refetch: refetchQuery } = useQuery(GET_FACTSHEET, {
        variables: {
            heiID: user?.heiID
        },

        // When request is completed, we set the state
        onCompleted: (data) => {
            setWeeksLimitTor(data?.factsheet?.tor_weeks_limit);
            setWeeksLimitRequirements(data?.factsheet?.decision_weeks_limit);
            setApplicationsWinterDate(data?.factsheet?.calendar?.student_app_autumn_term);
            setApplicationsSummerDate(data?.factsheet?.calendar?.student_app_spring_term);
            setNominationsWinterDate(data?.factsheet?.calendar?.student_nom_autumn_term);
            setNominationsSummerDate(data?.factsheet?.calendar?.student_nom_spring_term);

            setHousingEmail(data?.factsheet?.housing_info?.email);
            setHousingPhone(data?.factsheet?.housing_info?.phone_number?.e164);
            setHousingUrl(data?.factsheet?.housing_info?.website_urls);

            setApplicationsContactEmail(data?.factsheet?.application_info?.email);
            setApplicationsContactPhone(data?.factsheet?.application_info?.phone_number?.e164);
            setApplicationsContactUrl(data?.factsheet?.application_info?.website_urls);

            setVisaEmail(data?.factsheet?.visa_info?.email);
            setVisaPhone(data?.factsheet?.visa_info?.phone_number?.e164);
            setVisaUrl(data?.factsheet?.visa_info?.website_urls);

            setInsuranceEmail(data?.factsheet?.insurance_info?.email);
            setInsurancePhone(data?.factsheet?.insurance_info?.phone_number?.e164);
            setInsuranceUrl(data?.factsheet?.insurance_info?.website_urls);

            setRecognitionProcessArray(data?.factsheet?.additional_infos?.filter(info => info?.type === "Recognition proccess")?.
                map(info => ({
                    email: info?.info?.email,
                    phone: info?.info?.phone_number?.e164,
                    url: info?.info?.website_urls
                })))
            setOtherInfoArray(data?.factsheet?.additional_infos?.filter(info => info?.type === "Other useful information")?.
                map(info => ({
                    email: info?.info?.email,
                    phone: info?.info?.phone_number?.e164,
                    url: info?.info?.website_urls
                })))

            setAdditionalRequirementArray(data?.factsheet?.additional_requirements?.map(a => ({
                requirement: a?.name,
                details: a?.details,
                ects: a?.ects,
                eqf_level: a?.eqf_level,
                url: a?.information_websites,
                isced_f_code: a?.isced_f_code,
                isced_f_code_name: a?.isced_f_code_name
            })))
            setInfrastructureArray(data?.factsheet?.accessibilities?.filter(a => a?.type === "infrastructure")?.map(a => ({
                name: a?.name,
                description: a?.description,
                email: a?.info?.email,
                phone: a?.info?.phone_number?.e164,
                url: a?.info?.website_urls
            })))
            setServicesArray(data?.factsheet?.accessibilities?.filter(a => a?.type === "service")?.map(a => ({
                name: a?.name,
                description: a?.description,
                email: a?.info?.email,
                phone: a?.info?.phone_number?.e164,
                url: a?.info?.website_urls
            })))
        }
    });

    // Handle multiple 
    const handleAddAdditionalRequirementsFields = () => {
        const values = additionalRequirementArray ? [...additionalRequirementArray] : [];
        values.push({ requirement: "", details: "", url: "" });
        setAdditionalRequirementArray(values);
    }

    const handleRemoveAdditionalRequirementsFields = (index) => {
        let values = [...additionalRequirementArray]
        values.splice(index, 1);

        setAdditionalRequirementArray(values);
    }

    const handleInputAdditionalRequirementsRequirementChange = (index, event) => {
        let values = [...additionalRequirementArray];
        values[index].requirement = event.value;

        setAdditionalRequirementArray(values);
    }



    const handleInputAdditionalRequirementsDetailChange = (index, event) => {
        let values = [...additionalRequirementArray]
        values[index].details = event.target.value;

        setAdditionalRequirementArray(values);
    }

    const handleInputAdditionalRequirementsEQFChange = (index, event) => {
        let values = [...additionalRequirementArray];
        values[index].eqf_level = event.value;

        setAdditionalRequirementArray(values);
    }
    const handleInputAdditionalRequirementsISCEDChange = (index, event) => {
        let values = [...additionalRequirementArray];
        values[index].isced_f_code = event.value;

        setAdditionalRequirementArray(values);
    }

    const handleInputAdditionalRequirementsECTSChange = (index, event) => {
        let values = [...additionalRequirementArray]
        values[index].ects = event.target.value;

        setAdditionalRequirementArray(values);
    }

    const handleInputAdditionalRequirementsUrlChange = (index, event) => {
        let values = [...additionalRequirementArray]
        values[index].url = event.target.value;

        setAdditionalRequirementArray(values);
    }

    // Handle multiple 
    const handleAddServiceFields = () => {
        const values = servicesArray ? [...servicesArray] : [];
        values.push({ name: "", description: "", email: "", phone: "", url: "" });
        setServicesArray(values);
    }

    const handleRemoveServiceFields = (index) => {
        let values = [...servicesArray];
        values.splice(index, 1);

        setServicesArray(values);
    }

    const handleInputServiceNameChange = (index, event) => {
        let values = [...servicesArray]
        values[index].name = event.target.value;

        setServicesArray(values);
    }

    const handleInputServiceDescriptionChange = (index, event) => {
        let values = [...servicesArray];
        values[index].description = event.target.value;

        setServicesArray(values);
    }

    const handleInputServiceEmailChange = (index, event) => {
        let values = [...servicesArray]
        values[index].email = event.target.value;

        setServicesArray(values);
    }
    const handleInputServicePhoneChange = (index, event) => {
        let values = [...servicesArray]
        values[index].phone = event.target.value;

        setServicesArray(values);
    }
    const handleInputServiceUrlChange = (index, event) => {
        let values = [...servicesArray]
        values[index].url = event.target.value;

        setServicesArray(values);
    }

    // Handle multiple 
    const handleAddInfrastructureFields = () => {
        const values = infrastructureArray ? [...infrastructureArray] : [];
        values.push({ name: "", description: "", email: "", phone: "", url: "" });
        setInfrastructureArray(values);
    }

    const handleRemoveInfrastructureFields = (index) => {
        let values = [...infrastructureArray];
        values.splice(index, 1);
        setInfrastructureArray(values);
    }

    const handleInputInfrastructureNameChange = (index, event) => {
        let values = [...infrastructureArray];
        values[index].name = event.target.value;
        setInfrastructureArray(values);
    }

    const handleInputInfrastructureDescriptionChange = (index, event) => {
        let values = [...infrastructureArray];
        values[index].description = event.target.value;
        setInfrastructureArray(values);
    }

    const handleInputSInfrastructureEmailChange = (index, event) => {
        let values = [...infrastructureArray];
        values[index].email = event.target.value;
        setInfrastructureArray(values);
    }
    const handleInputInfrastructurePhoneChange = (index, event) => {
        let values = [...infrastructureArray];
        values[index].phone = event.target.value;
        setInfrastructureArray(values);
    }
    const handleInputInfrastructureUrlChange = (index, event) => {
        let values = [...infrastructureArray];
        values[index].url = event.target.value;
        setInfrastructureArray(values);
    }


    // Handle multiple 
    const handleAddRecognitionProcessFields = () => {
        const values = recognitionProcessArray ? [...recognitionProcessArray] : [];
        values.push({ email: "", phone: "", url: "" });
        setRecognitionProcessArray(values);
    }

    const handleRemoveRecognitionProcessFields = (index) => {
        let values = [...recognitionProcessArray]
        values.splice(index, 1);
        setRecognitionProcessArray(values);
    }

    const handleInputRecognitionProcessEmailChange = (index, event) => {
        let values = [...recognitionProcessArray]
        values[index].email = event.target.value;
        setRecognitionProcessArray(values);
    }
    const handleInputRecognitionProcessPhoneChange = (index, event) => {
        let values = [...recognitionProcessArray]
        values[index].phone = event.target.value;
        setRecognitionProcessArray(values);
    }
    const handleInputRecognitionProcessUrlChange = (index, event) => {
        let values = [...recognitionProcessArray]
        values[index].url = event.target.value;
        setRecognitionProcessArray(values);
    }

    // Handle multiple 
    const handleAddOtherInfoFields = () => {
        const values = otherInfoArray ? [...otherInfoArray] : [];
        values.push({ email: "", phone: "", url: "" });
        setOtherInfoArray(values);
    }

    const handleRemoveOtherInfoFields = (index) => {
        let values = [...otherInfoArray];
        values.splice(index, 1);
        setOtherInfoArray(values);
    }

    const handleInputOtherInfoEmailChange = (index, event) => {
        let values = [...otherInfoArray];
        values[index].email = event.target.value;
        setOtherInfoArray(values);
    }
    const handleInputOtherInfoPhoneChange = (index, event) => {
        let values = [...otherInfoArray];
        values[index].phone = event.target.value;
        setOtherInfoArray(values);
    }
    const handleInputOtherInfoUrlChange = (index, event) => {
        let values = [...otherInfoArray];
        values[index].url = event.target.value;

        setOtherInfoArray(values);
    }

    const getValueFromSelect = (el, field) => {
        if (el[field] === "" || el[field] === null || el[field] === undefined) {
            return null;
        } else {
            return ({
                "label": el[field]
            });
        }

    };


    const handleCancelFactsheetData = async (event) => {
        event.preventDefault();
        await refetchQuery();
    }

    const handleSubmitFactsheetData = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        setValidated(true);
        if (form.checkValidity() === false) {
            setFormInvalid(true);
        }
        else {
            setShowModal(!showModal)
            setFormInvalid(false);
        }
    }

    const handleSaveFactsheetData = async () => {

        try {
            const result = await updateFactsheet({
                variables:
                {
                    factsheetData: {
                        // flat fields 
                        additional_infos: recognitionProcessArray?.map(entry => ({
                            type: "Recognition proccess",

                            info: {
                                email: entry?.email,
                                phone_number: {
                                    ext: null,
                                    e164: entry?.phone,
                                    other_format: null
                                },
                                website_urls: { value: entry?.url }

                            }
                        }))?.concat(otherInfoArray?.
                            map(entry => ({
                                type: "Other useful information",
                                info: {
                                    email: entry?.email,
                                    phone_number: {
                                        ext: null,
                                        e164: entry?.phone,
                                        other_format: null
                                    },
                                    website_urls: { value: entry?.url }

                                }
                            }))),
                        additional_requirements:
                            additionalRequirementArray?.map(a => ({
                                details: a?.details ? a?.details : "",
                                information_websites: { value: a?.url },
                                name: a?.requirement,
                                ects: a?.ects,
                                eqf_level: a?.eqf_level ? String(a?.eqf_level) : "",
                                isced_f_code: a?.isced_f_code,
                                isced_f_code_name: a?.isced_f_code_name
                            })),
                        application_info: {
                            email: applicationsContactEmail,
                            phone_number: {
                                ext: null,
                                e164: applicationsContactPhone,
                                other_format: null
                            },
                            website_urls: { value: applicationsContactUrl },
                        },
                        tor_weeks_limit: Number(weeksLimitTor),
                        decision_weeks_limit: Number(weeksLimitRequirements),
                        visa_info: {
                            email: visaEmail,
                            phone_number: {
                                ext: null,
                                e164: visaPhone,
                                other_format: null
                            },
                            website_urls: { value: visaUrl },
                        },
                        housing_info: {
                            email: housingEmail,
                            phone_number: {
                                ext: null,
                                e164: housingPhone,
                                other_format: null
                            },
                            website_urls: { value: housingUrl },
                        },
                        insurance_info: {
                            email: insuranceEmail,
                            phone_number: {
                                ext: null,
                                e164: insurancePhone,
                                other_format: null
                            },
                            website_urls: { value: insuranceUrl },
                        },
                        hei_id: user?.heiID,
                        calendar: {
                            student_app_autumn_term: applicationsWinterDate,
                            student_app_spring_term: applicationsSummerDate,
                            student_nom_autumn_term: nominationsWinterDate,
                            student_nom_spring_term: nominationsSummerDate
                        },
                        accessibilities: infrastructureArray?.map(a => ({
                            type: "infrastructure",
                            name: a?.name,
                            description: a?.description,
                            info: {
                                email: a?.email,
                                phone_number: {
                                    ext: null,
                                    e164: a?.phone,
                                    other_format: null
                                },
                                website_urls: { value: a?.url },
                            }
                        }))?.concat(servicesArray?.map(a => ({
                            type: "service",
                            name: a?.name,
                            description: a?.description,
                            info: {
                                email: a?.email,
                                phone_number: {
                                    ext: null,
                                    e164: a?.phone,
                                    other_format: null
                                },
                                website_urls: { value: a?.url },
                            }
                        })))

                    }
                }
            });
            if (!result?.errors) {
                toast.success("Factsheet data updated successfully.", toastConfiguration);
                refetchQuery();
            }
        } catch (error) {
            if (error) {
                toast.error("There was an error with updating factsheet data.", toastConfiguration);
                alert("There was an error with updating factsheet data: " + error);
            }
        }
        setShowModal(false);
        setCheckAccept(false)
        setFormInvalid(false);
        setValidated(true);
        refetchQuery();

    }


    if (error || errorIsced)
        return (
            <div className="text-center text-danger mx-auto">An error occured</div>
        );

    if (loading || loadingIsced) return (
        <div
            style={{
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)"
            }}
        >
            <Spinner
                as="div"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
                className="align-middle my-auto"
            />
            <span>Loading...</span>
        </div>
    )

    return (
        <div className="d-flex justify-content-between mx-5 pt-4 pt-xxl-5">
            <Row >
                <Col style={{ marginTop: 10 }}>
                    <Form noValidate validated={validated} id={"upload-ola-form"} onSubmit={(event) => handleSubmitFactsheetData(event)}>
                        <Row>
                            <Accordion className='border-hidden label-style' id={calendarTab ? 'accordion-edit-factsheet-active' : 'accordion-edit-factsheet-inactive'} style={{ marginBottom: 15 }}>
                                <Accordion.Item eventKey="0" className='label-style' id={"accordion-first"}>
                                    <Accordion.Header onClick={() => setCalendarTab(!calendarTab)} className='document-item label-style'>CALENDAR</Accordion.Header>
                                    <Accordion.Body className='border-hidden label-style' /*style={{ background: "#F2F3F6" }}*/>
                                        <div style={{ background: "white", borderRadius: 10 }}>
                                            <Row style={{ marginBottom: 12, marginLeft: 5, marginRight: 1 }}>
                                                <div className='group-fields-title mt-4 mb-2'>
                                                    Student Nominations must reach the Receiving Institution by:
                                                </div>
                                                <Col md={6}>
                                                    <div style={{ marginBottom: 5, marginTop: 10 }} className='label-style'>
                                                        <Form.Group className="col-12" controlId="nominationsWinterDate">
                                                            <Form.Label>Autumn Term Date <span style={{ color: "red" }}>*</span></Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="--DD-MM"
                                                                value={nominationsWinterDate}
                                                                pattern='^--(?:(?:(0[1-9]|1[0-9]|2[0-9]|3[01])-(0[13578]|1[02]))|(?:(0[1-9]|1[0-9]|2[0-9]|30)-(0[469]|11))|(?:(0[1-9]|1[0-9]|2[0-9])-02))$'
                                                                required
                                                                onChange={(event) => setNominationsWinterDate(event.target.value)}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                Invalid date format
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div style={{ marginBottom: 5, marginTop: 10 }} className='label-style'>
                                                        <Form.Group className="col-12" controlId="nominationsSummerDate">
                                                            <Form.Label>Spring Term Date <span style={{ color: "red" }}>*</span></Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="--DD-MM"
                                                                value={nominationsSummerDate}
                                                                pattern='^--(?:(?:(0[1-9]|1[0-9]|2[0-9]|3[01])-(0[13578]|1[02]))|(?:(0[1-9]|1[0-9]|2[0-9]|30)-(0[469]|11))|(?:(0[1-9]|1[0-9]|2[0-9])-02))$'
                                                                required
                                                                onChange={(event) => setNominationsSummerDate(event.target.value)}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                Invalid date format
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row style={{ marginBottom: 12, marginLeft: 5, marginRight: 1 }}>
                                                <div className='group-fields-title mt-4 mb-2'>
                                                    Applications for Nominated Students must reach the Receiving Institution by:
                                                </div>
                                                <Col md={6}>
                                                    <div style={{ marginBottom: 5, marginTop: 10 }} className='label-style'>
                                                        <Form.Group className="col-12" controlId="applicationsWinterDate">
                                                            <Form.Label>Autumn Term Date <span style={{ color: "red" }}>*</span></Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="--DD-MM"
                                                                value={applicationsWinterDate}
                                                                pattern='^--(?:(?:(0[1-9]|1[0-9]|2[0-9]|3[01])-(0[13578]|1[02]))|(?:(0[1-9]|1[0-9]|2[0-9]|30)-(0[469]|11))|(?:(0[1-9]|1[0-9]|2[0-9])-02))$'
                                                                required
                                                                onChange={(event) => setApplicationsWinterDate(event.target.value)}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                Invalid date format
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div style={{ marginBottom: 5, marginTop: 10 }} className='label-style'>
                                                        <Form.Group className="col-12" controlId="applicationsSummerDate">
                                                            <Form.Label>Spring Term Date <span style={{ color: "red" }}>*</span></Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="--DD-MM"
                                                                value={applicationsSummerDate}
                                                                pattern='^--(?:(?:(0[1-9]|1[0-9]|2[0-9]|3[01])-(0[13578]|1[02]))|(?:(0[1-9]|1[0-9]|2[0-9]|30)-(0[469]|11))|(?:(0[1-9]|1[0-9]|2[0-9])-02))$'
                                                                required
                                                                onChange={(event) => setApplicationsSummerDate(event.target.value)}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                Invalid date format
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row style={{ marginBottom: 12, marginLeft: 5, marginRight: 1 }}>
                                                <div className='group-fields-title mt-4 mb-2'>
                                                    Application Procedure for Nominated Students:
                                                </div>
                                                <Col md={4}>
                                                    <div style={{ marginBottom: 5, marginTop: 10 }} className='label-style'>
                                                        <Form.Group className="col-12" controlId="formBasicName">
                                                            <Form.Label>Contact Email <span style={{ color: "red" }}>*</span></Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="example@email.com"
                                                                value={applicationsContactEmail}
                                                                required
                                                                pattern="^\S+@\S+\.\S+$"
                                                                onChange={(event) => setApplicationsContactEmail(event.target.value)}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                Not valid
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div style={{ marginBottom: 5, marginTop: 10 }} className='label-style'>
                                                        <Form.Group className="col-12" controlId="formBasicName">
                                                            <Form.Label>Contact Phone <span style={{ color: "red" }}>*</span></Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                // placeholder=""
                                                                value={applicationsContactPhone}
                                                                required
                                                                pattern="\+[0-9]{1,15}"
                                                                onChange={(event) => setApplicationsContactPhone(event.target.value)}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please enter a phone number that start with your country code (ex: +000).
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div style={{ marginBottom: 5, marginTop: 10 }} className='label-style'>
                                                        <Form.Group className="col-12" controlId="formBasicName">
                                                            <Form.Label>Website for information <span style={{ color: "red" }}>*</span></Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="https://example.com"
                                                                value={applicationsContactUrl}
                                                                required
                                                                pattern='https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)'
                                                                onChange={(event) => setApplicationsContactUrl(event.target.value)}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please enter an URL that start with http or https.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row style={{ marginBottom: 12, marginLeft: 5, marginRight: 1 }}>
                                                <div className='group-fields-title mt-4 mb-2'>
                                                    Default weeks Values:
                                                </div>
                                                <Col md={6}>
                                                    <div style={{ marginBottom: 5, marginTop: 10 }} className='label-style'>
                                                        <Form.Group className="col-12" controlId="formBasicName">
                                                            <Form.Label>Weeks for additional requirements decision <span style={{ color: "red" }}>*</span></Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder=""
                                                                value={weeksLimitRequirements}
                                                                required
                                                                pattern='[0-9]'
                                                                onChange={(event) => setWeeksLimitRequirements(event.target.value)}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                Not valid
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div style={{ marginBottom: 5, marginTop: 10 }} className='label-style'>
                                                        <Form.Group className="col-12" controlId="formBasicName">
                                                            <Form.Label>Weeks for transcript of records issue  <span style={{ color: "red" }}>*</span></Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder=""
                                                                value={weeksLimitTor}
                                                                required
                                                                pattern='[0-9]*'
                                                                onChange={(event) => setWeeksLimitTor(event.target.value)}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                Not valid
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion className='border-hidden label-style' id={additionalReqTab ? 'accordion-edit-factsheet-active' : 'accordion-edit-factsheet-inactive'} style={{ marginBottom: 15 }}>
                                <Accordion.Item eventKey="0" className='label-style' id={"accordion-first"}>
                                    <Accordion.Header onClick={() => setAdditionalReqTab(!additionalReqTab)} className='document-item label-style'>ADDITIONAL REQUIREMENTS</Accordion.Header>
                                    <Accordion.Body className='border-hidden label-style' style={{ background: "#F2F3F6" }}>
                                        <Row style={{ marginBottom: 12, marginLeft: 15, background: "#F2F3F6" }}>
                                            {additionalRequirementArray?.map((element, index) =>
                                                <>
                                                    <Row style={{ background: "white", borderRadius: 10 }}>
                                                        {(size === "xs" || size === "sm" || size === "md") ? <>
                                                            <Col lg={11} xs={12} style={{ display: "flex" }}>
                                                                <Form.Group className={"col-12"} controlId="formBasicName" style={{ marginBottom: 5, marginTop: 10, marginRight: 10 }}>
                                                                    <Form.Label>Requirement <span style={{ color: "red" }}>*</span></Form.Label>
                                                                    <Select
                                                                        options={requirementsList?.map((el) => ({
                                                                            value: el,
                                                                            label: el
                                                                        }))}
                                                                        placeholder="Select Requirement"
                                                                        onChange={(event) => handleInputAdditionalRequirementsRequirementChange(index, event)}
                                                                        validated={validated}
                                                                        invalid={!element?.requirement}
                                                                        value={getValueFromSelect(element, 'requirement')}

                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            {element?.requirement === "Academic requirements" ? <>
                                                                <Col lg={11} xs={12} style={{ display: "flex" }}>
                                                                    <Form.Group className={"col-12"} style={{ marginBottom: 5, marginTop: 10, marginRight: 10 }} controlId="formBasicName">
                                                                        <Form.Label>Number of ECTS Completed</Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            onChange={(event) => handleInputAdditionalRequirementsECTSChange(index, event)}
                                                                            value={element?.ects}
                                                                        />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col lg={11} xs={12} style={{ display: "flex" }}>
                                                                    <Form.Group className={"col-12"} style={{ marginBottom: 5, marginTop: 10, marginRight: 10 }} controlId="formBasicName">
                                                                        <Form.Label>Study Field (ISCED)</Form.Label>
                                                                        <Select
                                                                            options={dataIsced?.iscedCodes?.map((el) => ({
                                                                                value: el?.code,
                                                                                label: el?.code
                                                                            }))}
                                                                            placeholder="Select ISCED"
                                                                            onChange={(event) => handleInputAdditionalRequirementsISCEDChange(index, event)}
                                                                            value={getValueFromSelect(element, 'isced_f_code')}

                                                                        />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col lg={11} xs={12} style={{ display: "flex" }}>
                                                                    <Form.Group className={"col-12"} style={{ marginBottom: 5, marginTop: 10, marginRight: 10 }} controlId="formBasicName">
                                                                        <Form.Label>Study Level (EQF)</Form.Label>
                                                                        <Select
                                                                            options={[5, 6, 7, 8]?.map((el) => ({
                                                                                value: el,
                                                                                label: el
                                                                            }))}
                                                                            placeholder="Select EQF"
                                                                            onChange={(event) => handleInputAdditionalRequirementsEQFChange(index, event)}
                                                                            value={getValueFromSelect(element, 'eqf_level')}

                                                                        />
                                                                    </Form.Group>
                                                                </Col>
                                                            </> : <Col lg={11} xs={12} style={{ display: "flex" }}>
                                                                <Form.Group className={"col-12"} style={{ marginBottom: 5, marginTop: 10, marginRight: 10 }} controlId="formBasicName">
                                                                    <Form.Label>Details <span style={{ color: "red" }}>*</span></Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        onChange={(event) => handleInputAdditionalRequirementsDetailChange(index, event)}
                                                                        required
                                                                        value={element?.details}
                                                                    />
                                                                </Form.Group>
                                                            </Col>}
                                                            <Col lg={11} xs={12} style={{ display: "flex" }}>
                                                                <Form.Group className="col-12" controlId="formBasicName">
                                                                    <Form.Label>Website for information</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="https://example.com"
                                                                        value={element?.url}
                                                                        pattern='https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)'
                                                                        onChange={(event) => handleInputAdditionalRequirementsUrlChange(index, event)}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        Please enter an URL that start with http or https.
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Col></>
                                                            : <> <Col lg={11} xs={12} style={{ display: "flex" }}>
                                                                <Form.Group className={element?.requirement === "Academic requirements" ? "col-2" : "col-4"} controlId="formBasicName" style={{ marginBottom: 5, marginTop: 10, marginRight: 10 }}>
                                                                    <Form.Label>Requirement <span style={{ color: "red" }}>*</span></Form.Label>
                                                                    <Select
                                                                        options={requirementsList?.map((el) => ({
                                                                            value: el,
                                                                            label: el
                                                                        }))}
                                                                        placeholder="Select Requirement"
                                                                        onChange={(event) => handleInputAdditionalRequirementsRequirementChange(index, event)}
                                                                        validated={validated}
                                                                        invalid={!element?.requirement}
                                                                        value={getValueFromSelect(element, 'requirement')}
                                                                        name={'code'}
                                                                    />
                                                                </Form.Group>
                                                                {element?.requirement === "Academic requirements" ? <>
                                                                    <Form.Group className={"col-3"} style={{ marginBottom: 5, marginTop: 10, marginRight: 10 }} controlId="formBasicName">
                                                                        <Form.Label>Number of ECTS Completed</Form.Label>
                                                                        <Form.Control
                                                                            type="number"
                                                                            onChange={(event) => handleInputAdditionalRequirementsECTSChange(index, event)}
                                                                            value={element?.ects}
                                                                        />
                                                                    </Form.Group>
                                                                    <Form.Group className={"col-2"} style={{ marginBottom: 5, marginTop: 10, marginRight: 10 }} controlId="formBasicName">
                                                                        <Form.Label>Study Field (ISCED)</Form.Label>
                                                                        <Select
                                                                            options={dataIsced?.iscedCodes?.map((el) => ({
                                                                                value: el?.code,
                                                                                label: el?.code
                                                                            }))}
                                                                            placeholder="Select ISCED"
                                                                            onChange={(event) => handleInputAdditionalRequirementsISCEDChange(index, event)}
                                                                            value={getValueFromSelect(element, 'isced_f_code')}

                                                                        />
                                                                    </Form.Group>
                                                                    <Form.Group className={"col-2"} style={{ marginBottom: 5, marginTop: 10, marginRight: 10 }} controlId="formBasicName">
                                                                        <Form.Label>Study Level (EQF)</Form.Label>
                                                                        <Select
                                                                            options={[5, 6, 7, 8]?.map((el) => ({
                                                                                value: el,
                                                                                label: el
                                                                            }))}
                                                                            placeholder="Select EQF"
                                                                            onChange={(event) => handleInputAdditionalRequirementsEQFChange(index, event)}
                                                                            value={getValueFromSelect(element, 'eqf_level')}

                                                                        />
                                                                    </Form.Group>
                                                                </> :
                                                                    <Form.Group className={"col-4"} style={{ marginBottom: 5, marginTop: 10, marginRight: 10 }} controlId="formBasicName">
                                                                        <Form.Label>Details <span style={{ color: "red" }}>*</span></Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            onChange={(event) => handleInputAdditionalRequirementsDetailChange(index, event)}
                                                                            required
                                                                            value={element?.details}
                                                                        />
                                                                    </Form.Group>
                                                                }
                                                                <Form.Group className={element?.requirement === "Academic requirements" ? "col-3" : "col-4"} style={{ marginBottom: 5, marginTop: 10, marginRight: 10 }} controlId="formBasicName">
                                                                    <Form.Label>Website for information</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="https://example.com"
                                                                        value={element?.url}
                                                                        pattern='https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)'
                                                                        onChange={(event) => handleInputAdditionalRequirementsUrlChange(index, event)}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        Please enter an URL that start with http or https.
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Col></>}
                                                        {!(size === "xs" || size === "sm" || size === "md") &&
                                                            <Col lg={1} style={{ display: "flex", justifyContent: "flex-end", alignContent: "flex-end", alignItems: "center", marginTop: 10 }}>
                                                                <IconButton
                                                                    variant="delete"
                                                                    title="Delete additional requirement"
                                                                    onClick={() => handleRemoveAdditionalRequirementsFields(index)}
                                                                />
                                                            </Col>
                                                        }
                                                        {(size === "xs" || size === "sm" || size === "md") &&
                                                            <Col lg={1} style={{ display: "flex", justifyContent: "flex-end", alignContent: "flex-end", alignItems: "center", marginTop: 5 }}>
                                                                <IconButton
                                                                    variant="delete"

                                                                    title="Delete additional requirement"
                                                                    onClick={() => handleRemoveAdditionalRequirementsFields(index)}
                                                                />
                                                            </Col>
                                                        }
                                                    </Row>
                                                    {additionalRequirementArray.length - 1 !== index && <hr style={{ border: "2px solid #F2F3F6" }} />}
                                                </>
                                            )}
                                        </Row>
                                        <Row style={size !== "md" && size !== "lg" ? { marginLeft: 35, marginRight: 35 } : { marginLeft: 0, marginRight: 0 }}>
                                            <Col style={{ marginTop: 20, marginBottom: 16, display: "flex", justifyContent: "flex-end" }}>
                                                <CustomButton buttonType={"primary"} text={"Add Additional Requirement"} icon={faPlus} hasIcon styles={{ marginLeft: 20 }} handleClick={handleAddAdditionalRequirementsFields} small />
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion className='border-hidden label-style' id={inclusionTab ? 'accordion-edit-factsheet-active' : 'accordion-edit-factsheet-inactive'} style={{ marginBottom: 15 }}>
                                <Accordion.Item eventKey="0" className='label-style' id={"accordion-first"}>
                                    <Accordion.Header onClick={() => setInclusionTab(!inclusionTab)} className='document-item label-style'>INCLUSION AND ACCESSIBILITY</Accordion.Header>
                                    <Accordion.Body className='border-hidden label-style' style={{ background: "#F2F3F6" }}>
                                        <div className='group-fields-descriptions mt-2 mb-4 ml-2 mr-2'>
                                            The institution will provide support to incoming mobile participants with special needs, according to the requirements of the Erasmus Charter for Higher Education. Information and assistance can be provided by the following contact points and information sources:
                                        </div>
                                        <div className='group-fields-title mt-2 mb-4 ml-2 mr-2'>
                                            Infrastructure
                                        </div>
                                        <Row style={{ marginBottom: 12, marginLeft: 15, background: "#F2F3F6" }}>
                                            {infrastructureArray?.map((element, index) =>
                                                <>
                                                    <Row style={{ background: "white", borderRadius: 10 }}>
                                                        {(size === "xs" || size === "sm" || size === "md") ? <>
                                                            <Col lg={11} xs={12} style={{ display: "flex" }}>
                                                                <Form.Group className={"col-12"} controlId="formBasicName" style={{ marginBottom: 5, marginTop: 10, marginRight: 10 }}>
                                                                    <Form.Label>Name <span style={{ color: "red" }}>*</span></Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        onChange={(event) => handleInputInfrastructureNameChange(index, event)}
                                                                        required
                                                                        value={element?.name}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={11} xs={12} style={{ display: "flex" }}>
                                                                <Form.Group className={"col-12"} style={{ marginBottom: 5, marginTop: 10, marginRight: 10 }} controlId="formBasicName">
                                                                    <Form.Label>Description</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        onChange={(event) => handleInputInfrastructureDescriptionChange(index, event)}
                                                                        value={element?.description}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Form.Group className="col-12" controlId="formBasicName">
                                                                <Form.Label>Contact Email <span style={{ color: "red" }}>*</span></Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="example@email.com"
                                                                    value={element?.email}
                                                                    required
                                                                    pattern="^\S+@\S+\.\S+$"
                                                                    onChange={(event) => handleInputSInfrastructureEmailChange(index, event)}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    Not valid
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                            <Col md={4}>
                                                                <div style={{ marginBottom: 5, marginTop: 10 }} className='label-style'>
                                                                    <Form.Group className="col-12" controlId="formBasicName">
                                                                        <Form.Label>Contact Phone <span style={{ color: "red" }}>*</span></Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            // placeholder=""
                                                                            value={element?.phone}
                                                                            required
                                                                            pattern="\+[0-9]{1,15}"
                                                                            onChange={(event) => handleInputInfrastructurePhoneChange(index, event)}
                                                                        />
                                                                        <Form.Control.Feedback type="invalid">
                                                                            Please enter a phone number that start with your country code (ex: +000).
                                                                        </Form.Control.Feedback>
                                                                    </Form.Group>
                                                                </div>
                                                            </Col>
                                                            <Col md={4}>
                                                                <div style={{ marginBottom: 5, marginTop: 10 }} className='label-style'>
                                                                    <Form.Group className="col-12" controlId="formBasicName">
                                                                        <Form.Label>Website for information <span style={{ color: "red" }}>*</span></Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="https://example.com"
                                                                            value={element?.url}
                                                                            required
                                                                            pattern='https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)'
                                                                            onChange={(event) => handleInputInfrastructureUrlChange(index, event)}
                                                                        />
                                                                        <Form.Control.Feedback type="invalid">
                                                                            Please enter an URL that start with http or https.
                                                                        </Form.Control.Feedback>
                                                                    </Form.Group>
                                                                </div>
                                                            </Col>
                                                        </>
                                                            : <> <Col lg={11} xs={12} style={{ display: "flex" }}>
                                                                <Form.Group className={"col-2"} controlId="formBasicName" style={{ marginBottom: 5, marginTop: 10, marginRight: 10 }}>
                                                                    <Form.Label>Name <span style={{ color: "red" }}>*</span></Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        onChange={(event) => handleInputInfrastructureNameChange(index, event)}
                                                                        required
                                                                        value={element?.name}
                                                                    />
                                                                </Form.Group>
                                                                <Form.Group className={"col-2"} style={{ marginBottom: 5, marginTop: 10, marginRight: 10 }} controlId="formBasicName">
                                                                    <Form.Label>Description</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        onChange={(event) => handleInputInfrastructureDescriptionChange(index, event)}
                                                                        value={element?.description}
                                                                    />
                                                                </Form.Group>
                                                                <Form.Group className="col-2" style={{ marginBottom: 5, marginTop: 10, marginRight: 10 }} controlId="formBasicName">
                                                                    <Form.Label>Contact Email <span style={{ color: "red" }}>*</span></Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="example@email.com"
                                                                        value={element?.email}
                                                                        required
                                                                        pattern="^\S+@\S+\.\S+$"
                                                                        onChange={(event) => handleInputSInfrastructureEmailChange(index, event)}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        Not valid
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                                <Form.Group className="col-2" style={{ marginBottom: 5, marginTop: 10, marginRight: 10 }} controlId="formBasicName">
                                                                    <Form.Label>Contact Phone <span style={{ color: "red" }}>*</span></Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        // placeholder=""
                                                                        value={element?.phone}
                                                                        required
                                                                        pattern="\+[0-9]{1,15}"
                                                                        onChange={(event) => handleInputInfrastructurePhoneChange(index, event)}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        Please enter a phone number that start with your country code (ex: +000).
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                                <Form.Group className="col-3" style={{ marginBottom: 5, marginTop: 10, marginRight: 10 }} controlId="formBasicName">
                                                                    <Form.Label>Website for information <span style={{ color: "red" }}>*</span></Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="https://example.com"
                                                                        value={element?.url}
                                                                        required
                                                                        pattern='https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)'
                                                                        onChange={(event) => handleInputInfrastructureUrlChange(index, event)}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        Please enter an URL that start with http or https.
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Col></>}
                                                        {!(size === "xs" || size === "sm" || size === "md") &&
                                                            <Col lg={1} style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", marginTop: 10 }}>
                                                                <IconButton
                                                                    variant="delete"

                                                                    title="Delete infrastructure"
                                                                    onClick={() => handleRemoveInfrastructureFields(index)}
                                                                />
                                                            </Col>
                                                        }
                                                        {(size === "xs" || size === "sm" || size === "md") &&
                                                            <Col lg={1} style={{ display: "flex", justifyContent: "flex-end", alignContent: "flex-end", alignItems: "center", marginTop: 5 }}>
                                                                <IconButton
                                                                    variant="delete"

                                                                    title="Delete infrastructure"
                                                                    onClick={() => handleRemoveInfrastructureFields(index)}
                                                                />
                                                            </Col>
                                                        }
                                                    </Row>
                                                    {infrastructureArray.length - 1 !== index && <hr style={{ border: "2px solid #F2F3F6" }} />}
                                                </>
                                            )}

                                        </Row>
                                        <Row style={size !== "md" && size !== "lg" ? { marginLeft: 35, marginRight: 35 } : { marginLeft: 0, marginRight: 0 }}>
                                            <Col style={{ marginTop: 20, marginBottom: 16, display: "flex", justifyContent: "flex-end" }}>
                                                <CustomButton buttonType={"primary"} text={"Add Infrastructure"} icon={faPlus} hasIcon styles={{ marginLeft: 20 }} handleClick={handleAddInfrastructureFields} small />
                                            </Col>
                                        </Row>
                                        <div className='group-fields-title mt-2 mb-4 ml-2 mr-2'>
                                            Services
                                        </div>
                                        <Row style={{ marginBottom: 12, marginLeft: 15, background: "#F2F3F6" }}>
                                            {servicesArray?.map((element, index) =>
                                                <>
                                                    <Row style={{ background: "white", borderRadius: 10 }}>
                                                        {(size === "xs" || size === "sm" || size === "md") ? <>
                                                            <Col lg={11} xs={12} style={{ display: "flex" }}>
                                                                <Form.Group className={"col-12"} controlId="formBasicName" style={{ marginBottom: 5, marginTop: 10, marginRight: 10 }}>
                                                                    <Form.Label>Name <span style={{ color: "red" }}>*</span></Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        onChange={(event) => handleInputServiceNameChange(index, event)}
                                                                        required
                                                                        value={element?.name}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={11} xs={12} style={{ display: "flex" }}>
                                                                <Form.Group className={"col-12"} style={{ marginBottom: 5, marginTop: 10, marginRight: 10 }} controlId="formBasicName">
                                                                    <Form.Label>Description</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        onChange={(event) => handleInputServiceDescriptionChange(index, event)}
                                                                        value={element?.description}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Form.Group className="col-12" controlId="formBasicName">
                                                                <Form.Label>Contact Email <span style={{ color: "red" }}>*</span></Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="example@email.com"
                                                                    value={element?.email}
                                                                    required
                                                                    pattern="^\S+@\S+\.\S+$"
                                                                    onChange={(event) => handleInputServiceEmailChange(index, event)}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    Not valid
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                            <Col md={4}>
                                                                <div style={{ marginBottom: 5, marginTop: 10 }} className='label-style'>
                                                                    <Form.Group className="col-12" controlId="formBasicName">
                                                                        <Form.Label>Contact Phone <span style={{ color: "red" }}>*</span></Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            // placeholder=""
                                                                            value={element?.phone}
                                                                            required
                                                                            pattern="\+[0-9]{1,15}"
                                                                            onChange={(event) => handleInputServicePhoneChange(index, event)}
                                                                        />
                                                                        <Form.Control.Feedback type="invalid">
                                                                            Please enter a phone number that start with your country code (ex: +000).
                                                                        </Form.Control.Feedback>
                                                                    </Form.Group>
                                                                </div>
                                                            </Col>
                                                            <Col md={4}>
                                                                <div style={{ marginBottom: 5, marginTop: 10 }} className='label-style'>
                                                                    <Form.Group className="col-12" controlId="formBasicName">
                                                                        <Form.Label>Website for information <span style={{ color: "red" }}>*</span></Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="https://example.com"
                                                                            value={element?.url}
                                                                            required
                                                                            pattern='https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)'
                                                                            onChange={(event) => handleInputServiceUrlChange(index, event)}
                                                                        />
                                                                        <Form.Control.Feedback type="invalid">
                                                                            Please enter an URL that start with http or https.
                                                                        </Form.Control.Feedback>
                                                                    </Form.Group>
                                                                </div>
                                                            </Col>
                                                        </>
                                                            : <> <Col lg={11} xs={12} style={{ display: "flex" }}>
                                                                <Form.Group className={"col-2"} controlId="formBasicName" style={{ marginBottom: 5, marginTop: 10, marginRight: 10 }}>
                                                                    <Form.Label>Name <span style={{ color: "red" }}>*</span></Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        onChange={(event) => handleInputServiceNameChange(index, event)}
                                                                        required
                                                                        value={element?.name}
                                                                    />
                                                                </Form.Group>
                                                                <Form.Group className={"col-2"} style={{ marginBottom: 5, marginTop: 10, marginRight: 10 }} controlId="formBasicName">
                                                                    <Form.Label>Description</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        onChange={(event) => handleInputServiceDescriptionChange(index, event)}
                                                                        value={element?.description}
                                                                    />
                                                                </Form.Group>
                                                                <Form.Group className="col-2" style={{ marginBottom: 5, marginTop: 10, marginRight: 10 }} controlId="formBasicName">
                                                                    <Form.Label>Contact Email <span style={{ color: "red" }}>*</span></Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="example@email.com"
                                                                        value={element?.email}
                                                                        required
                                                                        pattern="^\S+@\S+\.\S+$"
                                                                        onChange={(event) => handleInputServiceEmailChange(index, event)}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        Not valid
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                                <Form.Group className="col-2" style={{ marginBottom: 5, marginTop: 10, marginRight: 10 }} controlId="formBasicName">
                                                                    <Form.Label>Contact Phone <span style={{ color: "red" }}>*</span></Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        // placeholder=""
                                                                        value={element?.phone}
                                                                        required
                                                                        pattern="\+[0-9]{1,15}"
                                                                        onChange={(event) => handleInputServicePhoneChange(index, event)}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        Please enter a phone number that start with your country code (ex: +000).
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                                <Form.Group className="col-3" style={{ marginBottom: 5, marginTop: 10, marginRight: 10 }} controlId="formBasicName">
                                                                    <Form.Label>Website for information <span style={{ color: "red" }}>*</span></Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="https://example.com"
                                                                        value={element?.url}
                                                                        required
                                                                        pattern='https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)'
                                                                        onChange={(event) => handleInputServiceUrlChange(index, event)}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        Please enter an URL that start with http or https.
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Col></>}
                                                        {!(size === "xs" || size === "sm" || size === "md") &&
                                                            <Col lg={1} style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", marginTop: 10 }}>
                                                                <IconButton
                                                                    variant="delete"
                                                                    title="Delete service"
                                                                    onClick={() => handleRemoveServiceFields(index)}
                                                                />
                                                            </Col>
                                                        }
                                                        {(size === "xs" || size === "sm" || size === "md") &&
                                                            <Col lg={1} style={{ display: "flex", justifyContent: "flex-end", alignContent: "flex-end", alignItems: "center", marginTop: 5 }}>
                                                                <IconButton
                                                                    variant="delete"
                                                                    title="Delete service"
                                                                    onClick={() => handleRemoveServiceFields(index)}
                                                                />
                                                            </Col>
                                                        }
                                                    </Row>
                                                    {servicesArray.length - 1 !== index && <hr style={{ border: "2px solid #F2F3F6" }} />}
                                                </>
                                            )}
                                        </Row>
                                        <Row style={size !== "md" && size !== "lg" ? { marginLeft: 35, marginRight: 35 } : { marginLeft: 0, marginRight: 0 }}>
                                            <Col style={{ marginTop: 20, marginBottom: 16, display: "flex", justifyContent: "flex-end" }}>
                                                <CustomButton buttonType={"primary"} text={"Add service"} icon={faPlus} hasIcon styles={{ marginLeft: 20 }} handleClick={handleAddServiceFields} small />
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion className='border-hidden label-style' id={housingTab ? 'accordion-edit-factsheet-active' : 'accordion-edit-factsheet-inactive'} style={{ marginBottom: 15 }}>
                                <Accordion.Item eventKey="0" className='label-style' id={"accordion-first"}>
                                    <Accordion.Header onClick={() => setHousingTab(!housingTab)} className='document-item label-style'>HOUSING</Accordion.Header>
                                    <Accordion.Body className='border-hidden label-style' /*style={{ background: "#F2F3F6" }}*/>
                                        <div style={{ background: "white", borderRadius: 10 }}>
                                            <div className='group-fields-descriptions mt-2 mb-4 ml-2 mr-2'>
                                                The institution will guide incoming mobile participants in finding accommodation, according to the requirements of the Erasmus Charter for Higher Education. Information and assistance can be provided by the following contact points and information sources:
                                            </div>
                                            <div className='group-fields-title mt-2 mb-2 ml-2 mr-2'>
                                                Contact
                                            </div>
                                            <Row style={{ marginBottom: 12, marginLeft: 5, marginRight: 1 }}>
                                                <Col md={4}>
                                                    <div style={{ marginBottom: 5, marginTop: 10 }} className='label-style'>
                                                        <Form.Group className="col-12" controlId="formBasicName">
                                                            <Form.Label>Contact Email <span style={{ color: "red" }}>*</span></Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="example@email.com"
                                                                value={housingEmail}
                                                                required
                                                                pattern="^\S+@\S+\.\S+$"
                                                                onChange={(event) => setHousingEmail(event.target.value)}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                Not valid
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div style={{ marginBottom: 5, marginTop: 10 }} className='label-style'>
                                                        <Form.Group className="col-12" controlId="formBasicName">
                                                            <Form.Label>Contact Phone <span style={{ color: "red" }}>*</span></Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                // placeholder=""
                                                                value={housingPhone}
                                                                required
                                                                pattern="\+[0-9]{1,15}"
                                                                onChange={(event) => setHousingPhone(event.target.value)}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please enter a phone number that start with your country code (ex: +000).
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div style={{ marginBottom: 5, marginTop: 10 }} className='label-style'>
                                                        <Form.Group className="col-12" controlId="formBasicName">
                                                            <Form.Label>Website for information <span style={{ color: "red" }}>*</span></Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="https://example.com"
                                                                value={housingUrl}
                                                                required
                                                                pattern='https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)'
                                                                onChange={(event) => setHousingUrl(event.target.value)}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please enter an URL that start with http or https.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion className='border-hidden label-style' id={visaTab ? 'accordion-edit-factsheet-active' : 'accordion-edit-factsheet-inactive'} style={{ marginBottom: 15 }}>
                                <Accordion.Item eventKey="0" className='label-style' id={"accordion-first"}>
                                    <Accordion.Header onClick={() => setVisaTab(!visaTab)} className='document-item label-style'>VISA</Accordion.Header>
                                    <Accordion.Body className='border-hidden label-style' /*style={{ background: "#F2F3F6" }}*/>
                                        <div style={{ background: "white", borderRadius: 10 }}>
                                            <div className='group-fields-descriptions mt-2 mb-4 ml-2 mr-2'>
                                                The institution will provide assistance, when required, in securing visas for incoming and outbound mobile participants, according to the requirements of the Erasmus Charter for Higher Education. Information and assistance can be provided by the following contact points and information sources:
                                            </div>
                                            <div className='group-fields-title mt-2 mb-2 ml-2 mr-2'>
                                                Contact
                                            </div>
                                            <Row style={{ marginBottom: 12, marginLeft: 5, marginRight: 1 }}>

                                                <Col md={4}>
                                                    <div style={{ marginBottom: 5, marginTop: 10 }} className='label-style'>
                                                        <Form.Group className="col-12" controlId="formBasicName">
                                                            <Form.Label>Contact Email <span style={{ color: "red" }}>*</span></Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="example@email.com"
                                                                value={visaEmail}
                                                                required
                                                                pattern="^\S+@\S+\.\S+$"
                                                                onChange={(event) => setVisaEmail(event.target.value)}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                Not valid
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div style={{ marginBottom: 5, marginTop: 10 }} className='label-style'>
                                                        <Form.Group className="col-12" controlId="formBasicName">
                                                            <Form.Label>Contact Phone <span style={{ color: "red" }}>*</span></Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                // placeholder=""
                                                                value={visaPhone}
                                                                required
                                                                pattern="\+[0-9]{1,15}"
                                                                onChange={(event) => setVisaPhone(event.target.value)}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please enter a phone number that start with your country code (ex: +000).
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div style={{ marginBottom: 5, marginTop: 10 }} className='label-style'>
                                                        <Form.Group className="col-12" controlId="formBasicName">
                                                            <Form.Label>Website for information <span style={{ color: "red" }}>*</span></Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="https://example.com"
                                                                value={visaUrl}
                                                                required
                                                                pattern='https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)'
                                                                onChange={(event) => setVisaUrl(event.target.value)}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please enter an URL that start with http or https.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion className='border-hidden label-style' id={insuranceTab ? 'accordion-edit-factsheet-active' : 'accordion-edit-factsheet-inactive'} style={{ marginBottom: 15 }}>
                                <Accordion.Item eventKey="0" className='label-style' id={"accordion-first"}>
                                    <Accordion.Header onClick={() => setInsuranceTab(!insuranceTab)} className='document-item label-style'>INSURANCE</Accordion.Header>
                                    <Accordion.Body className='border-hidden label-style' /*style={{ background: "#F2F3F6" }}*/>
                                        <div style={{ background: "white", borderRadius: 10 }}>
                                            <div className='group-fields-descriptions mt-2 mb-4 ml-2 mr-2'>
                                                The institution will provide assistance in obtaining insurance for incoming and outbound mobile participants, according to the requirements of the Erasmus Charter for Higher Education. The receiving institution will inform mobile participants of cases in which insurance cover is not automatically provided. Information and assistance can be provided by the following contact points and information sources:
                                            </div>
                                            <div className='group-fields-title mt-2 mb-2 ml-2 mr-2'>
                                                Contact
                                            </div>
                                            <Row style={{ marginBottom: 12, marginLeft: 5, marginRight: 1 }}>
                                                <Col md={4}>
                                                    <div style={{ marginBottom: 5, marginTop: 10 }} className='label-style'>
                                                        <Form.Group className="col-12" controlId="formBasicName">
                                                            <Form.Label>Contact Email <span style={{ color: "red" }}>*</span></Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="example@email.com"
                                                                value={insuranceEmail}
                                                                required
                                                                pattern="^\S+@\S+\.\S+$"
                                                                onChange={(event) => setInsuranceEmail(event.target.value)}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                Not valid
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div style={{ marginBottom: 5, marginTop: 10 }} className='label-style'>
                                                        <Form.Group className="col-12" controlId="formBasicName">
                                                            <Form.Label>Contact Phone <span style={{ color: "red" }}>*</span></Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                // placeholder=""
                                                                value={insurancePhone}
                                                                required
                                                                pattern="\+[0-9]{1,15}"
                                                                onChange={(event) => setInsurancePhone(event.target.value)}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please enter a phone number that start with your country code (ex: +000).
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div style={{ marginBottom: 5, marginTop: 10 }} className='label-style'>
                                                        <Form.Group className="col-12" controlId="formBasicName">
                                                            <Form.Label>Website for information <span style={{ color: "red" }}>*</span></Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="https://example.com"
                                                                value={insuranceUrl}
                                                                required
                                                                pattern='https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)'
                                                                onChange={(event) => setInsuranceUrl(event.target.value)}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please enter an URL that start with http or https.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion className='border-hidden label-style' id={additionalInfoTab ? 'accordion-edit-factsheet-active' : 'accordion-edit-factsheet-inactive'} style={{ marginBottom: 15 }}>
                                <Accordion.Item eventKey="0" className='label-style' id={"accordion-first"}>
                                    <Accordion.Header onClick={() => setAdditionalInfoTab(!additionalInfoTab)} className='document-item label-style'>ADDITIONAL INFORMATION</Accordion.Header>
                                    <Accordion.Body className='border-hidden label-style' style={{ background: "#F2F3F6" }}>
                                        <div className='group-fields-descriptions mt-2 mb-4 ml-2 mr-2'>
                                            The institution will provide support to incoming mobile participants with special needs, according to the requirements of the Erasmus Charter for Higher Education. Information and assistance can be provided by the following contact points and information sources:
                                        </div>
                                        <div className='group-fields-title mt-2 mb-4 ml-2 mr-2'>
                                            Recognition Process
                                        </div>
                                        <Row style={{ marginBottom: 12, marginLeft: 15, background: "#F2F3F6" }}>
                                            {recognitionProcessArray?.map((element, index) =>
                                                <>
                                                    <Row style={{ background: "white", borderRadius: 10 }}>
                                                        {(size === "xs" || size === "sm" || size === "md") ? <>
                                                            <Form.Group className="col-12" controlId="formBasicName">
                                                                <Form.Label>Contact Email <span style={{ color: "red" }}>*</span></Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="example@email.com"
                                                                    value={element?.email}
                                                                    required
                                                                    pattern="^\S+@\S+\.\S+$"
                                                                    onChange={(event) => handleInputRecognitionProcessEmailChange(index, event)}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    Not valid
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                            <Col md={4}>
                                                                <div style={{ marginBottom: 5, marginTop: 10 }} className='label-style'>
                                                                    <Form.Group className="col-12" controlId="formBasicName">
                                                                        <Form.Label>Contact Phone <span style={{ color: "red" }}>*</span></Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            // placeholder=""
                                                                            value={element?.phone}
                                                                            required
                                                                            pattern="\+[0-9]{1,15}"
                                                                            onChange={(event) => handleInputRecognitionProcessPhoneChange(index, event)}
                                                                        />
                                                                        <Form.Control.Feedback type="invalid">
                                                                            Please enter a phone number that start with your country code (ex: +000).
                                                                        </Form.Control.Feedback>
                                                                    </Form.Group>
                                                                </div>
                                                            </Col>
                                                            <Col md={4}>
                                                                <div style={{ marginBottom: 5, marginTop: 10 }} className='label-style'>
                                                                    <Form.Group className="col-12" controlId="formBasicName">
                                                                        <Form.Label>Website for information <span style={{ color: "red" }}>*</span></Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="https://example.com"
                                                                            value={element?.url}
                                                                            required
                                                                            pattern='https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)'
                                                                            onChange={(event) => handleInputRecognitionProcessUrlChange(index, event)}
                                                                        />
                                                                        <Form.Control.Feedback type="invalid">
                                                                            Please enter an URL that start with http or https.
                                                                        </Form.Control.Feedback>
                                                                    </Form.Group>
                                                                </div>
                                                            </Col>
                                                        </>
                                                            : <> <Col lg={11} xs={12} style={{ display: "flex" }}>
                                                                <Form.Group className="col-4" style={{ marginBottom: 5, marginTop: 10, marginRight: 10 }} controlId="formBasicName">
                                                                    <Form.Label>Contact Email <span style={{ color: "red" }}>*</span></Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="example@email.com"
                                                                        value={element?.email}
                                                                        required
                                                                        pattern="^\S+@\S+\.\S+$"
                                                                        onChange={(event) => handleInputRecognitionProcessEmailChange(index, event)}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        Not valid
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                                <Form.Group className="col-4" style={{ marginBottom: 5, marginTop: 10, marginRight: 10 }} controlId="formBasicName">
                                                                    <Form.Label>Contact Phone <span style={{ color: "red" }}>*</span></Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        value={element?.phone}
                                                                        required
                                                                        pattern="\+[0-9]{1,15}"
                                                                        onChange={(event) => handleInputRecognitionProcessPhoneChange(index, event)}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        Please enter a phone number that start with your country code (ex: +000).
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                                <Form.Group className="col-4" style={{ marginBottom: 5, marginTop: 10, marginRight: 10 }} controlId="formBasicName">
                                                                    <Form.Label>Website for information <span style={{ color: "red" }}>*</span></Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="https://example.com"
                                                                        value={element?.url}
                                                                        required
                                                                        pattern='https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)'
                                                                        onChange={(event) => handleInputRecognitionProcessUrlChange(index, event)}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        Please enter an URL that start with http or https.
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Col></>}
                                                        {!(size === "xs" || size === "sm" || size === "md") &&
                                                            <Col lg={1} style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", marginTop: 10 }}>
                                                                <IconButton
                                                                    variant="delete"

                                                                    title="Delete recognition process"
                                                                    onClick={() => handleRemoveRecognitionProcessFields(index)}
                                                                />
                                                            </Col>
                                                        }
                                                        {(size === "xs" || size === "sm" || size === "md") &&
                                                            <Col lg={1} style={{ display: "flex", justifyContent: "flex-end", alignContent: "flex-end", alignItems: "center", marginTop: 5 }}>
                                                                <IconButton
                                                                    variant="delete"

                                                                    title="Delete recognition process"
                                                                    onClick={() => handleRemoveRecognitionProcessFields(index)}
                                                                />
                                                            </Col>
                                                        }
                                                    </Row>
                                                    {recognitionProcessArray.length - 1 !== index && <hr style={{ border: "2px solid #F2F3F6" }} />}
                                                </>
                                            )}
                                        </Row>
                                        <Row style={size !== "md" && size !== "lg" ? { marginLeft: 35, marginRight: 35 } : { marginLeft: 0, marginRight: 0 }}>
                                            <Col style={{ marginTop: 20, marginBottom: 16, display: "flex", justifyContent: "flex-end" }}>
                                                <CustomButton buttonType={"primary"} text={"Add Recognition Process Info"} icon={faPlus} hasIcon styles={{ marginLeft: 20 }} handleClick={handleAddRecognitionProcessFields} small />
                                            </Col>
                                        </Row>
                                        <div className='group-fields-title mt-2 mb-4 ml-2 mr-2'>
                                            Other useful information
                                        </div>
                                        <Row style={{ marginBottom: 12, marginLeft: 15, background: "#F2F3F6" }}>
                                            {otherInfoArray?.map((element, index) =>
                                                <>
                                                    <Row style={{ background: "white", borderRadius: 10 }}>
                                                        {(size === "xs" || size === "sm" || size === "md") ? <>
                                                            <Form.Group className="col-12" controlId="formBasicName">
                                                                <Form.Label>Contact Email <span style={{ color: "red" }}>*</span></Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="example@email.com"
                                                                    value={element?.email}
                                                                    required
                                                                    pattern="^\S+@\S+\.\S+$"
                                                                    onChange={(event) => handleInputOtherInfoEmailChange(index, event)}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    Not valid
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                            <Col md={4}>
                                                                <div style={{ marginBottom: 5, marginTop: 10 }} className='label-style'>
                                                                    <Form.Group className="col-12" controlId="formBasicName">
                                                                        <Form.Label>Contact Phone <span style={{ color: "red" }}>*</span></Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            // placeholder=""
                                                                            value={element?.phone}
                                                                            required
                                                                            pattern="\+[0-9]{1,15}"
                                                                            onChange={(event) => handleInputOtherInfoPhoneChange(index, event)}
                                                                        />
                                                                        <Form.Control.Feedback type="invalid">
                                                                            Please enter a phone number that start with your country code (ex: +000).
                                                                        </Form.Control.Feedback>
                                                                    </Form.Group>
                                                                </div>
                                                            </Col>
                                                            <Col md={4}>
                                                                <div style={{ marginBottom: 5, marginTop: 10 }} className='label-style'>
                                                                    <Form.Group className="col-12" controlId="formBasicName">
                                                                        <Form.Label>Website for information <span style={{ color: "red" }}>*</span></Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="https://example.com"
                                                                            value={element?.url}
                                                                            required
                                                                            pattern='https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)'
                                                                            onChange={(event) => handleInputOtherInfoUrlChange(index, event)}
                                                                        />
                                                                        <Form.Control.Feedback type="invalid">
                                                                            Please enter an URL that start with http or https.
                                                                        </Form.Control.Feedback>
                                                                    </Form.Group>
                                                                </div>
                                                            </Col>
                                                        </>
                                                            : <> <Col lg={11} xs={12} style={{ display: "flex" }}>
                                                                <Form.Group className="col-4" style={{ marginBottom: 5, marginTop: 10, marginRight: 10 }} controlId="formBasicName">
                                                                    <Form.Label>Contact Email <span style={{ color: "red" }}>*</span></Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="example@email.com"
                                                                        value={element?.email}
                                                                        required
                                                                        pattern="^\S+@\S+\.\S+$"
                                                                        onChange={(event) => handleInputOtherInfoEmailChange(index, event)}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        Not valid
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                                <Form.Group className="col-4" style={{ marginBottom: 5, marginTop: 10, marginRight: 10 }} controlId="formBasicName">
                                                                    <Form.Label>Contact Phone <span style={{ color: "red" }}>*</span></Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        // placeholder=""
                                                                        value={element?.phone}
                                                                        required
                                                                        pattern="\+[0-9]{1,15}"
                                                                        onChange={(event) => handleInputOtherInfoPhoneChange(index, event)}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        Please enter a phone number that start with your country code (ex: +000).
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                                <Form.Group className="col-4" style={{ marginBottom: 5, marginTop: 10, marginRight: 10 }} controlId="formBasicName">
                                                                    <Form.Label>Website for information <span style={{ color: "red" }}>*</span></Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="https://example.com"
                                                                        value={element?.url}
                                                                        required
                                                                        pattern='https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)'
                                                                        onChange={(event) => handleInputOtherInfoUrlChange(index, event)}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        Please enter an URL that start with http or https.
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Col></>}
                                                        {!(size === "xs" || size === "sm" || size === "md") &&
                                                            <Col lg={1} style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", marginTop: 10 }}>
                                                                <IconButton
                                                                    variant="delete"

                                                                    title="Delete other info"
                                                                    onClick={() => handleRemoveOtherInfoFields(index)}
                                                                />
                                                            </Col>
                                                        }
                                                        {(size === "xs" || size === "sm" || size === "md") &&
                                                            <Col lg={1} style={{ display: "flex", justifyContent: "flex-end", alignContent: "flex-end", alignItems: "center", marginTop: 5 }}>
                                                                <IconButton
                                                                    variant="delete"

                                                                    title="Delete other info"
                                                                    onClick={() => handleRemoveOtherInfoFields(index)}
                                                                />
                                                            </Col>
                                                        }
                                                    </Row>
                                                    {otherInfoArray.length - 1 !== index && <hr style={{ border: "2px solid #F2F3F6" }} />}
                                                </>
                                            )}
                                        </Row>
                                        <Row style={size !== "md" && size !== "lg" ? { marginLeft: 35, marginRight: 35 } : { marginLeft: 0, marginRight: 0 }}>
                                            <Col style={{ marginTop: 20, marginBottom: 16, display: "flex", justifyContent: "flex-end" }}>
                                                <CustomButton buttonType={"primary"} text={"Add Other useful Info"} icon={faPlus} hasIcon styles={{ marginLeft: 20 }} handleClick={handleAddOtherInfoFields} small />
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Col>
                                <Col style={{ marginTop: 76, marginBottom: 10, display: "flex", justifyContent: "flex-end" }}>
                                    <CustomButton buttonType={"outlined"} text={"Cancel"} styles={{ marginLeft: 20, background: "#EAECF3 !important" }} disabled={loading || loadingUpdateFactsheet} handleClick={(event) => handleCancelFactsheetData(event)} small />
                                    <CustomButton type="submit" buttonType={"primary"} text={"save factsheet data"} icon={faFloppyDisk} disabled={loading || loadingUpdateFactsheet} hasIcon styles={{ marginLeft: 20 }} small />
                                </Col>
                            </Col>
                            <Row>
                                <Col>
                                    <Col style={{ marginBottom: 36, display: "flex", justifyContent: "flex-end" }}>
                                        {formInvalid && <div style={{ color: "red" }}>The form contains errors</div>}
                                    </Col>
                                </Col>
                            </Row>
                        </Row>
                        <CustomModal
                            show={showModal}
                            handleClose={() => {
                                setCheckAccept(false);
                                setShowModal(false);
                            }}
                            disabledCancelButton={loading || loadingUpdateFactsheet}
                            body={
                                <Form.Group controlId="formAuthorizations">
                                    <Form.Check type="checkbox" id="check-authorizations">
                                        <Form.Check.Input type="checkbox" required onChange={() => setCheckAccept(!checkAccept)} />
                                        <Form.Check.Label>
                                            I confirm, also on behalf of my HEI, that I have all the authorisations, including the consent of the relevant natural persons, necessary to upload the personal data and information I am providing, pursuant to the Dashboard Terms & Conditions and Privacy Policy.
                                        </Form.Check.Label>
                                        <Form.Control.Feedback type="invalid">
                                            Please give consent on the treatment of data
                                        </Form.Control.Feedback>
                                    </Form.Check>
                                </Form.Group>
                            }
                            button={<CustomButton buttonType={"primary"} text={"save factsheet data"} icon={faFloppyDisk} disabled={loading || loadingUpdateFactsheet || !checkAccept} hasIcon styles={{ marginLeft: 20 }} small handleClick={handleSaveFactsheetData} />}
                            title={"You are about to edit your factsheet data"}
                            hasIcon
                            fullscreen
                        />
                    </Form>
                </Col>
            </Row>
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>)
};

export default EditFactsheet;