import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import GET_NOMINATION_PDF from "../../../graphql/queries/nomination/getNominationPdf";
import withTitle from "../../../components/withTitle";

const ExportPdf = () => {
  const { uuid } = useParams();

  const { data } = useQuery(GET_NOMINATION_PDF, {
    variables: {
      uuid: uuid,
    },
  });

  let pdf = data?.nominationPdf;

  if (pdf) {
    let byteCharacters = atob(pdf);
    let byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    let byteArray = new Uint8Array(byteNumbers);
    let file = new Blob([byteArray], { type: "application/pdf;base64" });
    const fileURL = URL.createObjectURL(file);

    return (
      <>
        <object
          style={{ width: "100%", height: "100vh" }}
          type="application/pdf"
          data={fileURL}
        >
          <p>
            Download the PDF by clicking <a href={fileURL}>here</a>.
          </p>
        </object>
      </>
    );
  }
};

export default withTitle(ExportPdf, "Export nomination to PDF");
