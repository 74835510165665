import { gql } from "@apollo/client";

const GET_NOMINATION_PDF = gql`
query nominationPdf($uuid: String!)
    {nominationPdf(uuid: $uuid)

    
}`
;

export default GET_NOMINATION_PDF;
