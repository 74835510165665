import { Col, Overlay, Row, Spinner, Table, Tooltip } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import withFooter from '../../../../components/WithFooter';
import withNavBar from '../../../../components/WithNavBar';
import "./index.scss"
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faEye,
  faFileZipper,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import COLORS from '../../../../data/colors';
import useBreakpoint from '../../../../hooks/useBreakpoint';
import CustomButton from '../../../../components/CustomButton';
import CustomModal from '../../../../components/CustomModal';
import { useState } from 'react';
import { useRef } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { StatusNomination } from '../../../../data/statusNomination';
import GET_INCOMING_NOMINATION from '../../../../graphql/queries/nomination/getIncomingNomination';
import ACCEPT_NOMINATION from '../../../../graphql/mutations/nomination/acceptNomination';
import REJECT_DESTINATION from '../../../../graphql/mutations/nomination/rejectDestination';
import ExportZip from '../../ExportZip';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import toastConfiguration from "../../../../data/toastConfiguration";
import { useEffect } from 'react';

const SingleViewIncomingNominations = () => {
    const [ size ] = useBreakpoint();
    const tooltipTarget = useRef(null);
    const { id } = useParams();

    const [showTooltip, setShowTooltip] = useState(false);
    const [showModalReject, setShowModalReject] = useState(false);
    const [showModalNominate, setShowModalNominate] = useState(false);

    const { loading, error, data, refetch: refetchQuery } = useQuery(
        GET_INCOMING_NOMINATION,
        {
          variables: {
            id: id
          }
        }
    );

    useEffect(() => {
        refetchQuery({
            id: id
        });
      }, []);

    const [
        acceptNomination, { loading: loadingAcceptNomination }
      ] = useMutation(ACCEPT_NOMINATION);

    const [
        rejectDestination, { loading: loadingRejectDestination }
    ] = useMutation(REJECT_DESTINATION);

    const handleCloseModalReject = () => setShowModalReject(false);
    const handleShowModalReject = () => setShowModalReject(true);
    const handleCloseModalNominate = () => setShowModalNominate(false);
    const handleShowModalNominate = () => setShowModalNominate(true);
    const handleShowTooltip = () => setShowTooltip(true);
    const handleCloseTooltip = () => setShowTooltip(false);

    if (loading) return (
        <div
            style={{
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)"
            }}
        >
            <Spinner
                as="div"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
                className="align-middle my-auto"
            />
            <span>Loading...</span>
        </div>
    )
    if (error)
      return (
        <div className="text-center text-danger mx-auto">An error occured</div>
      );

    const field_student_name = data.incomingNomination.field_student_name;
    const field_home_hei_name = data.incomingNomination.field_mobility_details[0]?.field_home_name;
    const field_home_hei_org_unit = data.incomingNomination.field_mobility_details[0]?.field_home_org_unit;
    const field_student_email = data.incomingNomination.field_student_email;
    const field_student_isced = data.incomingNomination.field_student_isced;
    const field_id = data.incomingNomination.id;
    const field_student_eqf = data.incomingNomination.field_student_eqf;
    const field_cv = data.incomingNomination.field_cv;
    const field_motivation_letter = data.incomingNomination.field_motivation_letter;
    const field_external_cert = data.incomingNomination.field_external_cert;
    const field_lang_level = data.incomingNomination.field_lang_level;
    const field_other_document = data.incomingNomination.field_other_document;
    const field_academic_year = data.incomingNomination.field_academic_year;
    const field_academic_term = data.incomingNomination.field_academic_term;
    const field_reference_contact = data.incomingNomination.field_reference_contact;
    const field_portfolio = data.incomingNomination.field_portfolio;
    const field_other_requirement = data.incomingNomination.field_other_requirement;
    const nominationStatus = data.incomingNomination.field_nomination_status;
    const createdDate = new Date(data.incomingNomination.created).toLocaleDateString('en-GB',{
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short'
    });

    const handleNominate = async () => {
        const toastId = toast.loading("Nominating application...", toastConfiguration);
        try {
            const result = await acceptNomination({
                variables: {
                  id: field_id,
                },
              });
              setShowModalNominate(false);
              if (!result?.errors) {
                toast.update(toastId, {
                    ...toastConfiguration,
                    render: `Succesfully nominated Application`,
                    type: "success",
                    isLoading: false,
                  });
                refetchQuery({
                    id: id
                });
            }
        } catch (error) {
            if (error) {
                toast.update(toastId, {
                    ...toastConfiguration,
                    render: `Nomination of application failed`,
                    type: "error",
                    isLoading: false,
                  });
            }
        }
        
    }

    const handleReject = async () => {
        const toastId = toast.loading("Rejecting application...", toastConfiguration);

        try {
            const result = await rejectDestination({
                variables: {
                  id: data.incomingNomination?.field_mobility_details[0]?.id,
                },
              });
              setShowModalReject(false);
                if (!result?.errors) {
                toast.update(toastId, {
                    ...toastConfiguration,
                    render: `Succesfully rejected Application`,
                    type: "success",
                    isLoading: false,
                    });
                refetchQuery({
                    id: id
                });
            }
        } catch (error) {
            if (error) {
                toast.update(toastId, {
                    ...toastConfiguration,
                    render: `Rejection of application failed`,
                    type: "error",
                    isLoading: false,
                  });
            }
        }
        
    }

  return (
    <>
    {(size !== "xs" && size !== "sm" && size !== "md") && ( <>
    <Row style={ size !== "md" && size !== "lg" && size !== "xl" ? {marginLeft: 35, marginRight: 35} : { marginLeft: 0, marginRight: 0}}>
        <Col style={{marginTop: 75}}>
            <Tabs
            id="application-info"
            >
            <Tab eventKey="home" title="APPLICATION INFO" style={{background: "#ffff"}}>
            <Table responsive>
                <thead className='thead-font'>
                    <tr>
                    <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 24}} className='border-hidden label-style'>ID</th>
                    <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>STATUS</th>
                    <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>ACADEMIC YEAR</th>
                    <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>ACADEMIC TERM</th>
                    <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>SUBMISSION TIME</th>
                    <th className='border-hidden'></th>
                    </tr>
                </thead>
                <tbody className='tbody-font'>
                    <tr>
                    <td style={{paddingTop: 0, paddingBottom: 20, paddingLeft: 24}} className='border-hidden body'>{field_id}</td>
                    <td style={{paddingTop: 0, paddingBottom: 20}} className='border-hidden  body'>{StatusNomination[nominationStatus]}</td>
                    <td style={{paddingTop: 0, paddingBottom: 20}} className='border-hidden  body'>{field_academic_year}</td>
                    <td style={{paddingTop: 0, paddingBottom: 20}} className='border-hidden  body'>{field_academic_term}</td>
                    <td style={{paddingTop: 0, paddingBottom: 20}} className='border-hidden  body'>{createdDate}</td>
                    <td style={{paddingTop: 0, marginTop: -5}} className="download-wrap border-hidden">
                        <Overlay
                            target={tooltipTarget.current}
                            show={showTooltip}
                            placement="top"
                        >
                            {(props) => (
                            <Tooltip
                                id="overlay-example"
                                {...props}
                                className="position-absolute"
                            >
                                    Download All Files
                            </Tooltip>
                            )}
                        </Overlay>
                        <div ref={tooltipTarget}>
                            <ExportZip id={field_id} onMouseEnter={handleShowTooltip} onMouseLeave={handleCloseTooltip} studentName={field_student_name}/>
                        </div>
                    </td>
                    </tr>
                </tbody>
                </Table>
            </Tab>
            </Tabs>
        </Col>
    </Row>
    <Row style={size !== "md" && size !== "lg" && size !== "xl" ? {marginLeft: 35, marginRight: 35} : { marginLeft: 0, marginRight: 0}}>
        <Col style={{marginTop: 36}}>
            <Tabs
            id="second-tab"
            >
            <Tab eventKey="general-info" id="general-info" title="General Info" style={{background: "#EAECF3"}}>
            <Row style={{marginLeft: 5, marginRight: 5}}>
                <Col lg={12} style={{marginTop: 20, marginBottom: 20}}>
                    <Tabs
                        defaultActiveKey="home"
                        id="student-info"
                        >
                        <Tab eventKey="home" title="STUDENT INFO" style={{background: "#ffff"}}>
                            <Table responsive>
                                <thead>
                                    <tr>
                                    <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 24}} className='border-hidden label-style'>NAME</th>
                                    <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>EMAIL</th>
                                    <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>STUDY LEVEL</th>
                                    <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>STUDY FIELD</th>
                                    </tr>
                                </thead>
                                <tbody className='tbody-font'>
                                    <tr>
                                    <td style={{paddingTop: 0, paddingBottom: 20, paddingLeft: 24}} className='border-hidden body'>{field_student_name}</td>
                                    <td style={{paddingTop: 0, paddingBottom: 20}} className='border-hidden body'>{field_student_email}</td>
                                    <td style={{paddingTop: 0, paddingBottom: 20}} className='border-hidden body'>{field_student_eqf}</td>
                                    <td style={{paddingTop: 0, paddingBottom: 20}} className='border-hidden body'>{field_student_isced}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Tab>
                    </Tabs>
                </Col>
                <Col style={{marginTop: 20, marginBottom: 20}}>
                    <Tabs
                        defaultActiveKey="home"
                        id="student-info"
                        >
                        <Tab eventKey="home" title="HOME HEI" style={{background: "#ffff"}}>
                            <Table responsive>
                                <thead>
                                    <tr>
                                    <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 24}} className='border-hidden label-style'>NAME</th>
                                    <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>ORGANIZATION UNIT</th>
                                    </tr>
                                </thead>
                                <tbody className='tbody-font'>
                                    <tr>
                                    <td style={{paddingTop: 0, paddingBottom: 20, paddingLeft: 24}} className='border-hidden body'>{field_home_hei_name}</td>
                                    <td style={{paddingTop: 0, paddingBottom: 20}} className='border-hidden body'>{field_home_hei_org_unit}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
            </Tab>
            <Tab eventKey="documents" id="documents" title="Documents" style={{background: "#EAECF3"}}>
            <Row style={{marginLeft: 25, marginRight: 25}}>
                <Col style={{marginTop: 30, marginBottom: 12}} md={12} lg={12}>
                {(field_cv?.id) &&
                <>
                    <Col style={{marginBottom: 12, paddingRight: 3}} md={12} lg={12}>
                        <div className='document-item label-style'><span style={{float: "left", paddingLeft: 17}}>CV</span>
                            <div style={{marginBottom: 12, float: "right", marginRight: 20}}>
                            <a href={`/applications-for-erasmus+/incoming-students/export/${field_cv?.id}`} target="_blank">
                                <CustomButton buttonType={"primary"} text={"View Pdf"} small={true} icon={faEye} hasIcon={true} iconColor={COLORS.white}/>
                            </a>
                            </div>
                        </div>
                    </Col>
                </>
                }

                {(field_portfolio?.id) &&
                <>
                    <Col style={{marginBottom: 12, paddingRight: 3}} md={12} lg={12}>
                        <div className='document-item label-style'><span style={{float: "left", paddingLeft: 17}}>PORTFOLIO</span>
                            <div style={{marginBottom: 12, float: "right", marginRight: 20}}>
                                <a href={`/applications-for-erasmus+/incoming-students/export/${field_portfolio?.id}`} target="_blank">
                                    <CustomButton buttonType={"primary"} text={"View Pdf"} small={true} icon={faEye} hasIcon={true} iconColor={COLORS.white}/>
                                </a>
                            </div>
                        </div>
                    </Col>
                </>
                }

                {(field_external_cert?.id) &&
                <>
                    <Col style={{marginBottom: 12, paddingRight: 3}} md={12} lg={12}>
                        <div className='document-item label-style'><span style={{float: "left", paddingLeft: 17}}>EXTERNAL CERTIFICATE</span>
                            <div style={{marginBottom: 12, float: "right", marginRight: 20}}>
                                <a href={`/applications-for-erasmus+/incoming-students/export/${field_external_cert?.id}`} target="_blank">
                                    <CustomButton buttonType={"primary"} text={"View Pdf"} small={true} icon={faEye} hasIcon={true} iconColor={COLORS.white}/>
                                </a>
                            </div>
                        </div>
                    </Col>
                </>
                }
                {(field_reference_contact?.id) && 
                <>
                    <Col style={{marginBottom: 12, paddingRight: 3}} md={12} lg={12}>
                        <div className='document-item label-style'><span style={{float: "left", paddingLeft: 17}}>REFERENCE CONTACT</span>
                            <div style={{marginBottom: 12, float: "right", marginRight: 20}}>
                                <a href={`/applications-for-erasmus+/incoming-students/export/${field_reference_contact?.id}`} target="_blank">
                                    <CustomButton buttonType={"primary"} text={"View Pdf"} small={true} icon={faEye} hasIcon={true} iconColor={COLORS.white}/>
                                </a>
                            </div>
                        </div>
                    </Col>
                </>
                }
                
                {(field_motivation_letter?.id) &&
                <>
                    <Col style={{marginBottom: 12, paddingRight: 3}} md={12} lg={12}>
                        <div className='document-item label-style'><span style={{float: "left", paddingLeft: 17}}>MOTIVATION LETTER</span>
                            <div style={{marginBottom: 12, float: "right", marginRight: 20}}>
                                <a href={`/applications-for-erasmus+/incoming-students/export/${field_motivation_letter?.id}`} target="_blank">
                                    <CustomButton buttonType={"primary"} text={"View Pdf"} small={true} icon={faEye} hasIcon={true} iconColor={COLORS.white}/>
                                </a>
                            </div>
                        </div>
                    </Col>
                </>
                }

                {(field_lang_level?.length > 0) &&
                <>
                    
                    <Accordion className='border-hidden label-style' style={{marginBottom: 12}}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>LANGUAGE CERTIFICATES</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style'>
                                {field_lang_level.map((element, index) => 
                                <Col style={{marginBottom: 12, paddingRight: 3, background: "#F2F3F6"}} md={12} lg={12}>
                                <div className='document-language-item label-style'><span style={{float: "left", paddingLeft: 13}}>{element?.fileName}</span>
                                    <div style={{marginBottom: 12, float: "right", marginRight: 20}}>
                                        <a href={`/applications-for-erasmus+/incoming-students/export/${element?.id}`} target="_blank">
                                            <CustomButton buttonType={"primary"} text={"View Pdf"} small={true} icon={faEye} hasIcon={true} iconColor={COLORS.white}/>
                                        </a>
                                    </div>
                                </div>
                                </Col>
                                )}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </>
                }

                {(field_other_document?.length > 0) &&
                <>
                    
                    <Accordion className='border-hidden label-style' style={{marginBottom: 12}}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>OTHER DOCUMENTS</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style'>
                                {field_other_document.map((element, index) => 
                                <Col style={{marginBottom: 12, paddingRight: 3, background: "#F2F3F6"}} md={12} lg={12}>
                                <div className='document-language-item label-style'><span style={{float: "left", paddingLeft: 13}}>{element?.fileName}</span>
                                    <div style={{marginBottom: 12, float: "right", marginRight: 20}}>
                                        <a href={`/applications-for-erasmus+/incoming-students/export/${element?.id}`} target="_blank">
                                            <CustomButton buttonType={"primary"} text={"View Pdf"} small={true} icon={faEye} hasIcon={true} iconColor={COLORS.white}/>
                                        </a>
                                    </div>
                                </div>
                                </Col>
                                )}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </>
                }
                </Col>
            </Row>
            </Tab>
            </Tabs>
        </Col>
    </Row>
    <Row style={size !== "md" && size !== "lg" ? {marginLeft: 35, marginRight: 35} : { marginLeft: 0, marginRight: 0}}>
        <Col style={{marginTop: 36, display: "flex", justifyContent: "flex-end"}}>
            <CustomButton buttonType={"decline"} text={"Reject"} styles={{marginLeft: 20}} icon={faXmark} hasIcon={true} disabled={(nominationStatus === 'accepted_by_receiving' || 
            nominationStatus === 'rejected_by_receiving' || nominationStatus === 'rejected_by_student'|| nominationStatus === 'cancelled')} iconColor={COLORS.white}  handleClick={handleShowModalReject}/>
            <CustomButton buttonType={"accept"} text={"Nominate"} styles={{marginLeft: 20}} icon={faCheck} hasIcon={true} iconColor={COLORS.white} disabled={(nominationStatus === 'accepted_by_receiving' || 
            nominationStatus === 'rejected_by_receiving' || nominationStatus === 'rejected_by_student'|| nominationStatus === 'cancelled')} handleClick={handleShowModalNominate}/>
        </Col>
    </Row>
</>
  )}
  {(size === "xs" || size === "sm" || size === "md") && (
    <>
    <Row style={{marginLeft: 2, marginRight: 5}}>
        <Col style={{marginTop: 15, paddingLeft: 0, paddingRight: 0}} xs={12} sm={12}>
            <Tabs
            id="application-info"
            >
            <Tab eventKey="home" title="APPLICATION INFO" style={{background: "#ffff"}}>
                <Row style={{marginLeft: 25,marginRight: 25}}>
                    <Col xs={6} sm={6} style={{marginTop: 17}} className='thead-font label-style'>ID:</Col>
                    <Col xs={6} sm={6} style={{marginTop: 17}} className='tbody-font body'>{field_id}</Col>
                    <Col xs={6} sm={6} style={{marginTop: 6}} className='thead-font label-style'>STATUS:</Col>
                    <Col xs={6} sm={6} style={{marginTop: 6}} className='tbody-font body'>{StatusNomination[nominationStatus]}</Col>
                    <Col xs={6} sm={6} style={{marginTop: 6}} className='thead-font label-style'>ACADEMIC YEAR:</Col>
                    <Col xs={6} sm={6} style={{marginTop: 6}} className='tbody-font body'>{field_academic_year}</Col>
                    <Col xs={6} sm={6} style={{marginTop: 6}} className='thead-font label-style'>ACADEMIC TERM:</Col>
                    <Col xs={6} sm={6} style={{marginTop: 6}} className='tbody-font body'>{field_academic_term}</Col>
                    <Col xs={6} sm={6} style={{marginTop: 6,marginBottom: 25}} className='thead-font label-style'>SUBMISSION TIME:</Col>
                    <Col xs={6} sm={6} style={{marginTop: 6,marginBottom: 25}} className='tbody-font body'>{createdDate}</Col>
                    <Col xs={12} sm={12} style={{marginTop: 6,marginBottom: 15}} className='tbody-font body text-end'>
                    <Overlay
                            target={tooltipTarget.current}
                            show={showTooltip}
                            placement="top"
                        >
                            {(props) => (
                            <Tooltip
                                id="overlay-example"
                                {...props}
                                className="position-absolute"
                            >
                                    Download All Files
                            </Tooltip>
                            )}
                        </Overlay>
                        <div ref={tooltipTarget}>
                            <ExportZip id={field_id} onMouseEnter={handleShowTooltip} onMouseLeave={handleCloseTooltip} studentName={field_student_name}/>
                        </div>
                        </Col>
                </Row>
            </Tab>
            </Tabs>
        </Col>
        <Col style={{marginTop: 15, paddingLeft: 0, paddingRight: 0}} xs={12} sm={12}>
            <Tabs
            id="second-tab"
            >
            <Tab eventKey="general-info" id="general-info" title="General Info" style={{background: "#EAECF3"}}>
                <Row style={{marginLeft: 15, marginRight: 15}}>
                    <Col style={{marginTop: 20, marginBottom:38, paddingLeft: 0, paddingRight: 0}}>
                        <Tabs
                            defaultActiveKey="home"
                            id="student-info"
                        >
                            <Tab eventKey="home" title="STUDENT INFO" style={{background: "#ffff"}}>
                                <Row style={{marginLeft: 25,marginRight: 25}}>
                                    <Col xs={6} sm={6} style={{marginTop: 17}} className='thead-font label-style'>NAME:</Col>
                                    <Col xs={6} sm={6} style={{marginTop: 17}} className='tbody-font body'>{field_student_name}</Col>
                                    <Col xs={6} sm={6} style={{marginTop: 6}} className='thead-font label-style'>EMAIL:</Col>
                                    <Col xs={6} sm={6} style={{marginTop: 6}} className='tbody-font body'>{field_student_email}</Col>
                                    <Col xs={6} sm={6} style={{marginTop: 6}} className='thead-font label-style'>STUDY LEVEL:</Col>
                                    <Col xs={6} sm={6} style={{marginTop: 6}} className='tbody-font body'>{field_student_eqf}</Col>
                                    <Col xs={6} sm={6} style={{marginTop: 6, marginBottom: 25}} className='thead-font label-style'>STUDY FIELD:</Col>
                                    <Col xs={6} sm={6} style={{marginTop: 6, marginBottom: 25}} className='tbody-font body'>{field_student_isced}</Col>
                                </Row>
                            </Tab>
                        </Tabs>
                    </Col>
                    <Col style={{marginTop: 20, marginBottom:38, paddingLeft: 0, paddingRight: 0}}>
                        <Tabs
                            defaultActiveKey="home"
                            id="student-info"
                        >
                            <Tab eventKey="home" title="HOME HEI" style={{background: "#ffff"}}>
                                <Row style={{marginLeft: 25,marginRight: 25}}>
                                    <Col xs={6} sm={6} style={{marginTop: 17}} className='thead-font label-style'>NAME:</Col>
                                    <Col xs={6} sm={6} style={{marginTop: 17}} className='tbody-font body'>{field_home_hei_name}</Col>
                                    <Col xs={6} sm={6} style={{marginTop: 6, marginBottom: 25}} className='thead-font label-style'>ORGANIZATION UNIT:</Col>
                                    <Col xs={6} sm={6} style={{marginTop: 6, marginBottom: 25}} className='tbody-font body'>{field_home_hei_org_unit}</Col>
                                </Row>
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            </Tab>
            <Tab eventKey="documents" id="documents" title="Documents" style={{background: "#EAECF3"}}>
            <Row style={{marginLeft: 5, marginRight: 5}}>
                <Col style={{marginTop: 30, marginBottom: 12}} md={12} lg={12}>
                {(field_cv?.id) &&
                <>
                    <Col style={{marginBottom: 12, paddingRight: 3}} md={12} lg={12}>
                        <div className='document-item label-style'><span style={{float: "left", paddingLeft: 17}}>CV</span>
                            <div style={{marginBottom: 12, float: "right", marginRight: 5}}>
                                <a href={`/applications-for-erasmus+/incoming-students/export/${field_cv?.id}`} target="_blank">
                                    <CustomButton buttonType={"primary"} text={"View Pdf"} small={true} icon={faEye} hasIcon={true} iconColor={COLORS.white}/>
                                </a>
                            </div>
                        </div>
                    </Col>
                </>
                }

                {(field_portfolio?.id) &&
                <>
                    <Col style={{marginBottom: 12, paddingRight: 3}} md={12} lg={12}>
                        <div className='document-item label-style'><span style={{float: "left", paddingLeft: 17}}>PORTFOLIO</span>
                            <div style={{marginBottom: 12, float: "right", marginRight: 5}}>
                                <a href={`/applications-for-erasmus+/incoming-students/export/${field_portfolio?.id}`} target="_blank">
                                    <CustomButton buttonType={"primary"} text={"View Pdf"} small={true} icon={faEye} hasIcon={true} iconColor={COLORS.white}/>
                                </a>
                            </div>
                        </div>
                    </Col>
                </>
                }

                {(field_external_cert?.id) &&
                <>
                    <Col style={{marginBottom: 12, paddingRight: 3}} md={12} lg={12}>
                        <div className='document-item label-style'><span style={{float: "left", paddingLeft: 17}}>EXTERNAL CERTIFICATE</span>
                            <div style={{marginBottom: 12, float: "right", marginRight: 5}}>
                                <a href={`/applications-for-erasmus+/incoming-students/export/${field_external_cert?.id}`} target="_blank">
                                    <CustomButton buttonType={"primary"} text={"View Pdf"} small={true} icon={faEye} hasIcon={true} iconColor={COLORS.white}/>
                                </a>
                            </div>
                        </div>
                    </Col>
                </>
                }
                {(field_reference_contact?.id) && 
                <>
                    <Col style={{marginBottom: 12, paddingRight: 3}} md={12} lg={12}>
                        <div className='document-item label-style'><span style={{float: "left", paddingLeft: 17}}>REFERENCE CONTACT</span>
                            <div style={{marginBottom: 12, float: "right", marginRight: 5}}>
                                <a href={`/applications-for-erasmus+/incoming-students/export/${field_reference_contact?.id}`} target="_blank">
                                    <CustomButton buttonType={"primary"} text={"View Pdf"} small={true} icon={faEye} hasIcon={true} iconColor={COLORS.white}/>
                                </a>
                            </div>
                        </div>
                    </Col>
                </>
                }
                
                {(field_motivation_letter?.id) &&
                <>
                    <Col style={{marginBottom: 12, paddingRight: 3}} md={12} lg={12}>
                        <div className='document-item label-style'><span style={{float: "left", paddingLeft: 17}}>MOTIVATION LETTER</span>
                            <div style={{marginBottom: 12, float: "right", marginRight: 5}}>
                                <a href={`/applications-for-erasmus+/incoming-students/export/${field_motivation_letter?.id}`} target="_blank">
                                    <CustomButton buttonType={"primary"} text={"View Pdf"} small={true} icon={faEye} hasIcon={true} iconColor={COLORS.white}/>
                                </a>
                            </div>
                        </div>
                    </Col>
                </>
                }

                {(field_lang_level?.length > 0) &&
                <>
                    <Accordion className='border-hidden label-style' style={{marginBottom: 12}}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>LANGUAGE CERTIFICATES</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style'>
                                {field_lang_level.map((element, index) => 
                                <Col style={{marginBottom: 12, paddingRight: 3, background: "#F2F3F6"}} md={12} lg={12}>
                                <div className='document-language-item label-style'><span style={{float: "left", paddingLeft: 5}}>{element?.fileName}</span>
                                    <div style={{marginBottom: 12, float: "right", marginRight: 5}}>
                                        <a href={`/applications-for-erasmus+/incoming-students/export/${element?.id}`} target="_blank">
                                            <CustomButton buttonType={"primary"} text={"View Pdf"} small={true} icon={faEye} hasIcon={true} iconColor={COLORS.white}/>
                                        </a>
                                    </div>
                                </div>
                                </Col>
                                )}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </>
                }

                {(field_other_document?.length > 0) &&
                <>
                    <Accordion className='border-hidden label-style' style={{marginBottom: 12}}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>OTHER DOCUMENTS</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style'>
                                {field_other_document.map((element, index) => 
                                <Col style={{marginBottom: 12, paddingRight: 3, background: "#F2F3F6"}} md={12} lg={12}>
                                <div className='document-language-item label-style'><span style={{float: "left", paddingLeft: 5}}>{element?.fileName}</span>
                                    <div style={{marginBottom: 12, float: "right", marginRight: 5}}>
                                        <a href={`/applications-for-erasmus+/incoming-students/export/${element?.id}`} target="_blank">
                                            <CustomButton buttonType={"primary"} text={"View Pdf"} small={true} icon={faEye} hasIcon={true} iconColor={COLORS.white}/>
                                        </a>
                                    </div>
                                </div>
                                </Col>
                                )}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </>
                }
                </Col>
                </Row>
            </Tab>
            </Tabs>
        </Col>
        <Col style={{marginTop: 15, paddingLeft: 0, paddingRight: 0}} xs={6} sm={6}>
            <Button className='wrapper-reject-mobile col-12' style={{display: "flex", justifyContent: "center"}} disabled={(nominationStatus === 'accepted_by_receiving' || 
            nominationStatus === 'rejected_by_receiving' || nominationStatus === 'rejected_by_student'|| nominationStatus === 'cancelled')} onClick={handleShowModalReject}> 
                <span >
                    <FontAwesomeIcon
                    icon={faXmark}
                    color={COLORS.white}
                    className="button-waiting"
                    />
                </span>REJECT
            </Button>
        </Col>
        <Col style={{marginTop: 15, paddingLeft: 5, paddingRight: 0, display: "flex", justifyContent: "flex-end",}} xs={6} sm={6}>
            <Button className='wrapper-nominate-mobile col-12' style={{display: "flex", justifyContent: "center"}} disabled={(nominationStatus === 'accepted_by_receiving' || 
            nominationStatus === 'rejected_by_receiving' || nominationStatus === 'rejected_by_student'|| nominationStatus === 'cancelled')} onClick={handleShowModalNominate}> 
                <span >
                    <FontAwesomeIcon
                    icon={faCheck}
                    color={COLORS.white}
                    className="button-waiting"
                    />
                </span>NOMINATE
            </Button>
        </Col>
    </Row>
    </>
  )}
    <CustomModal show={showModalReject}  handleClose={handleCloseModalReject} 
            body={"After rejecting the application, the student will not be able to apply for any other mobility program at your HEI, in the current academic year. However, the Sending IRO may be able to nominate this Student for a mobility in another HEI, if available."}
            button={<CustomButton buttonType={"decline"} text={"Reject"} disabled={loadingRejectDestination} icon={faXmark} hasIcon={true} iconColor={COLORS.white}  handleClick={handleReject}/>} 
            title={<div>You are about to <span style={{color: "red"}}>reject</span> a student’s application</div>} hasIcon={false} disabledCancelButton={loadingRejectDestination}/>
    <CustomModal show={showModalNominate}  handleClose={handleCloseModalNominate} 
            body={"By nominating the student, you are making the student will be eligible to start their mobility in the HEI you represent."}
            button={<CustomButton buttonType={"accept"} text={"Nominate"} disabled={loadingAcceptNomination} icon={faCheck} hasIcon={true} iconColor={COLORS.white}  handleClick={handleNominate}/>} 
            title={<div>You are about to <span style={{color: "green"}}>nominate</span> a student’s application</div>} hasIcon disabledCancelButton={loadingAcceptNomination}/>
    <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
  </>)
};

export default withFooter(
    withNavBar(SingleViewIncomingNominations, "Applications for Erasmus+", "Incoming Students")
);