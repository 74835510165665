import { Children, useEffect } from "react";

import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX, faSliders } from "@fortawesome/free-solid-svg-icons";

import TableRow from "./components/TableRow/";
import TableHeader from "./components/TableHeader";
import Pagination from "../Pagination/";
import CustomButton from "../CustomButton";

import "./index.scss";
import COLORS from "../../data/colors";
import { useTable } from "./hooks/useTable";
import useBreakpoint from "../../hooks/useBreakpoint";
import { StatusMapping } from "../../data/statusMapping";
import { useNavigate } from "react-router-dom";
import { StatusNomination } from "../../data/statusNomination";
import { OlaSortingMapping } from "../../data/olaSortingMapping";

export const replaceUnderscoreAndCamelCaseToString = (str) => {
  return str
    .replace(/([A-Z])/g, " $1")
    .replace(/([A-Z])/g, " $1")
    .replaceAll("_", " ")
    .toUpperCase();
};

const FiltersButton = ({ handleExpand, expandFilters }) => {
  return (
    <CustomButton
      handleClick={() => handleExpand(!expandFilters)}
      buttonType={"primary"}
      text={"Filters"}
      small={true}
      icon={faSliders}
      hasIcon={true}
      iconColor={COLORS.white}
    />
  );
};

const CustomTable = ({
  data,
  page,
  setPage,
  type,
  expandFilters,
  setExpandFilters,
  filters,
  setFilters,
  sortBy,
  setSortBy,
  columnsPerFieldMap,
  rowClassName,
  headerClassName,
  hasFilters,
  hasPagination,
  pageLimit,
  totalCount,
  onClickHandlers = [],
  children,
  customTitle,
  onMouseDownHandlers
}) => {
  const [size] = useBreakpoint();

  const initialState = {
    tableData: data,
    tableHeaders: data?.length ? Object.keys(data[0]) : [],
    isMobile: size === "xs" || size === "sm" || size === "md",
    total: totalCount,
    filters: [],
    expandFilters: false,
  };

  const { state, setIsMobile } = useTable(initialState);
  const { tableData, tableHeaders, isMobile, total } = state;

  const handleExpand = (value) => {
    setExpandFilters(value);
  };

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        setIsMobile(size === "xs" || size === "sm" || size === "md");
      },
      false
    );
  }, [size, setIsMobile]);

  const shouldRenderFilters = (filter) => {
    if (filter === "") {
      return false;
    } else if (filter === null) {
      return false;
    } else if (Array.isArray(filter) && filter.length === 0) {
      return false;
    }
    return true;
  };

  const renderFilterButton = (idx, key, value) => {
    return (
      <div key={"row-" + idx}>
        <Button
          variant="primary"
          style={{
            borderRadius: 50,
            background: "#F2F3F6",
            border: "1px solid #3D619C",
            color: "#3D619C",
            marginRight: "10px",
            marginBottom: "5px",
            marginTop: "5px",
          }}
          onClick={() => setFilters(key, value)}
        >
          {value}
          <span style={{ marginLeft: 8 }}>
            <FontAwesomeIcon icon={faX} size="1x" color={COLORS.blueText} />
          </span>
        </Button>
      </div>
    );
  };

  const renderFilterValue = (idx, key, value) => {
    if (key === "status" && (type.includes("ola-typeOfStudent")||type.includes("iia"))) {
      return renderFilterButton(
        idx,
        key,
        !isNaN(parseInt(value) || value?.includes("_"))
          ? StatusMapping[value]
          : value
      );
    } else if (key === "status" && type.includes("nominations")) {
      return renderFilterButton(
        idx,
        key,
        !isNaN(parseInt(value) || value?.includes("_"))
          ? StatusNomination[value]
          : value
      );
    } else if (key === "country") {
      return value.map((i) => renderFilterButton(idx, key, i));
    }
    else if(key==="erasmusCode" && type.includes("iia")){
      return
    }
    return renderFilterButton(idx, key, value);
  };

  const renderSortByButton = () => {
    if (type.includes("ola-typeOfStudent") || type.includes("nominations") || type.includes("iia") || type.includes("requests")) {
      return (<div key={"row-sortBy"}>
      <Button
        variant="primary"
        style={{
          borderRadius: 50,
          background: "#F2F3F6",
          border: "1px solid #3D619C",
          color: "#3D619C",
          marginRight: "10px",
          marginBottom: "5px",
          marginTop: "5px",
        }}
        onClick={() => setSortBy()}
      >
        {OlaSortingMapping[sortBy]}
        <span style={{ marginLeft: 8 }}>
          <FontAwesomeIcon icon={faX} size="1x" color={COLORS.blueText} />
        </span>
      </Button>
    </div>)
    } 
    return(<div key={"row-sortBy"}>
      <Button
        variant="primary"
        style={{
          borderRadius: 50,
          background: "#F2F3F6",
          border: "1px solid #3D619C",
          color: "#3D619C",
          marginRight: "10px",
          marginBottom: "5px",
          marginTop: "5px",
        }}
        onClick={() => setSortBy()}
      >
        {sortBy}
        <span style={{ marginLeft: 8 }}>
          <FontAwesomeIcon icon={faX} size="1x" color={COLORS.blueText} />
        </span>
      </Button>
    </div>)
  };

  return (
    <div className="py-2">
      <div className="col-12 pt-0 pl-3 pr-0 mx-auto">
        <div className="mx-1 mx-xxl-5">
          {hasFilters && (
            <div
              className={`${
                isMobile ? "" : "d-flex justify-content-start mb-2"
              }`}
            >
              {!isMobile && (
                <div
                  className="table-title h6-style my-3"
                  style={{ marginRight: "2%" }}
                >
                  {customTitle || "Total Agreements"}: {total}
                </div>
              )}
              {isMobile && (
                <div className="d-flex">
                  <div
                    className="table-title h6-style my-3"
                    style={{ marginRight: "2%" }}
                  >
                    {customTitle || "Total Agreements"}: {total}
                  </div>
                  <div style={{ marginLeft: "auto" }}>
                    <FiltersButton
                      handleExpand={handleExpand}
                      expandFilters={expandFilters}
                    />
                  </div>
                </div>
              )}
              <div
                className={`my-3 ${isMobile ? "hide" : ""}`}
                style={{
                  marginRight: "2%",
                  border: "1px solid #DDDDDD",
                }}
              ></div>
              <div className="table-title active-filters my-3">
                <div className="d-flex align-items-center flex-wrap">
                  <div className="h6-style" style={{ marginRight: 5 }}>
                    Active Filters:
                  </div>
                  {shouldRenderFilters(sortBy) && renderSortByButton()}
                  {filters && Object.keys(filters).map(
                    (f, idx) =>
                      shouldRenderFilters(filters[f]) &&
                      renderFilterValue(idx, f, filters[f])
                  )}
                </div>
              </div>
              {!isMobile && (
                <div className="d-flex align-items-center">
                  <FiltersButton
                    handleExpand={handleExpand}
                    expandFilters={expandFilters}
                  />
                </div>
              )}
            </div>
          )}
          {tableData?.length !== 0 ? (
            <>
              <TableHeader
                labels={tableHeaders}
                isMobile={isMobile}
                fieldToHeader={replaceUnderscoreAndCamelCaseToString}
                columnsPerFieldMap={columnsPerFieldMap}
                className={`table-header ${
                  Children.count(children) !== 0 ? `${headerClassName}` : ``
                }`}
              />
              <>
                {tableData?.map((row, index) => {
                  return (
                    <TableRow
                      key={"row-id-" + (row.id || index)}
                      row={row}
                      labels={tableHeaders}
                      isMobile={isMobile}
                      fieldToHeader={replaceUnderscoreAndCamelCaseToString}
                      type={type}
                      columnsPerFieldMap={columnsPerFieldMap}
                      rowClassName={rowClassName}
                      className={`${
                        onClickHandlers.length > index
                          ? "table-row-with-on-click-handler"
                          : ""
                      }`}
                      onClickHandler={
                        onClickHandlers.length > index
                          ? onClickHandlers[index]
                          : undefined
                      }
                      onMouseDown={
                        onMouseDownHandlers?.length > index
                          ? onMouseDownHandlers[index]
                          : undefined
                      }
                    >
                      {Children.count(children) > index
                        ? children[index]
                        : null}
                    </TableRow>
                  );
                })}
                {hasPagination && (
                  <div className="d-flex justify-content-center">
                    <Pagination
                      total={total}
                      page={page}
                      pageLimit={pageLimit}
                      setPage={setPage}
                    />
                  </div>
                )}
              </>
            </>
          ) : (
            <div className="text-center mt-4">
              <i
                className="fa fa-frown-o fa-5x no-results"
                aria-hidden="true"
              ></i>
              <h5 className="text-center mt-2">
                Sorry, we couldn't find any results
              </h5>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomTable;
