export let BACKEND_URL = "http://localhost:4000/graphql";

if (process.env.NODE_ENV === "production") {
  if (process.env.REACT_APP_HOST_ENV === "staging")
    BACKEND_URL = "https://ewpdashbnd-stg01.it.auth.gr/graphql";

  if (process.env.REACT_APP_HOST_ENV === "production")
    BACKEND_URL = "https://ewpdashbnd.it.auth.gr/graphql";
}

export const ACCOUNT_TYPES = Object.freeze({
  hei: "HEI",
  eufAdmin: "EUFadmin",
});
