import React, { useEffect, useState } from "react";
import "./index.scss";
import Offcanvas from "react-bootstrap/Offcanvas";
import SidebarMenu from "react-bootstrap-sidebar-menu";
import { Col, Form, Row } from "react-bootstrap";
import Select from "../Select";
import MultiSelect from "../MultiSelect";
import { StatusMapping } from "../../data/statusMapping";
import CustomButton from "../CustomButton";
import { StatusNomination } from "../../data/statusNomination";
import { useQuery } from "@apollo/client";
import GET_ERASMUS_CODES from "../../graphql/queries/registration/getErasmusCodes";
import { OlaSortingMapping } from "../../data/olaSortingMapping";

const statuses_ola = [
  "preliminary_unsigned",
  "preliminary_signed-by-student",
  "preliminary_signed-by-student-sending",
  "preliminary_signed-by-student-sending-receiving",
  "final_unsigned",
  "final_signed-by-student",
  "final_signed-by-student-sending",
  "final_signed-by-student-sending-receiving",
];

const statuses_iia = [
  "draft",
  "submitted-p1",
  "approved-p1",
  "submitted-p2",
  "approved-p2",
  "approved-by-all"
];

const statuses_nominations = [
  "accepted_by_sending",
  "accepted_by_student",
  "accepted_by_receiving",
  "rejected_by_receiving",
  "submitted",
  "rejected_by_sending",
  "cancelled",
  "rejected_by_student",
  "waiting_list",
];

const createdBy = ["Created by Student", "Created by Institution"];

const academicYears = [
  "2021/2022",
  "2022/2023",
  "2023/2024",
  "2024/2025",
  "2025/2026",
  "2026/2027",
  "2027/2028",
  "2028/2029",
];

const academicTerms = [
  "First semester (Winter/Autumn)",
  "Second semester (Summer/Spring)",
  "First trimester",
  "Second trimester",
  "Full academic year",
];

const sortingList = [
  { value: "status", label: "Sorting by Status" },
  { value: "changed_ascending", label: "Sorting by Last Changed Ascending" },
  { value: "changed_descending", label: "Sorting by Last Changed Descending" },
  { value: "name", label: "Sorting by Name" },
];

const pendingRequestsOptions = [
  { value: 0, label: "Pending" },
  { value: 1, label: "Accepted" },
  {
    value: "-1",
    label: "Declined",
  },
];

const Filters = ({
  expand,
  handleExpand,
  selectedFiltersList,
  setSelectedFiltersList,
  sortBy,
  setSortBy,
  countries,
  type,
  setPage,
}) => {
  const [show, setShow] = useState(false);
  const [activeStatus, setActiveStatus] = useState(false);
  const [activeYear, setActiveYear] = useState(false);
  const [activeTerm, setActiveTerm] = useState(false);
  const [activeEC, setActiveEC] = useState(false);
  const [activeCountry, setActiveCountry] = useState(false);
  const [activeCreatedBy, setActiveCreatedBy] = useState(false);
  const [activeName, setActiveName] = useState(false);
  const [activeEmail, setActiveEmail] = useState(false);
  const [activeISCED, setActiveISCED] = useState(false);
  const [activeEQF, setActiveEQF] = useState(false);
  const [activeSortBy, setActiveSortBy] = useState(false);
  const [selectedValues, setSelectedValues] = useState(selectedFiltersList);
  const [selectedSortBy, setSelectedSortBy] = useState(sortBy);
  const [academicYearFrom, setAcademicYearFrom] = useState(
    selectedFiltersList?.academicYear?.split("/")?.at(0)
  );
  const [academicYearTo, setAcademicYearTo] = useState(
    selectedFiltersList?.academicYear?.split("/")?.at(1)
  );

  const {
    loading: loadingEcodes,
    data: ecodes,
    error: errorEcodes,
    refetch: refetchEcodes
  } = useQuery(GET_ERASMUS_CODES, {
    variables: {
      countryCodes: countries?.length > 0 ? countries?.filter(c=>selectedFiltersList["country"].includes(c?.CountryName))?.map(c=>c?.ID) : []
    }
});

  useEffect(() => {
    setShow(expand);
  }, [expand]);

  useEffect(() => {
    const validAcademicYear = () => {
      var from, to;
      if (academicYearFrom) {
        from = Number(academicYearFrom);
      }
      if (academicYearTo) {
        to = Number(academicYearTo);
      }
      if (from && to) {
        if (typeof from == "number" && typeof to == "number")
          if (from <= to) {
            return true;
          }
      }

      return false;
    };
    if (validAcademicYear()) {
      setSelectedValues((prevState) => {
        let temp = Object.assign({}, prevState);
        temp.academicYear = academicYearFrom + "/" + academicYearTo;
        return temp;
      });
    } else {
      setSelectedValues((prevState) => {
        let temp = Object.assign({}, prevState);
        temp.academicYear = "";
        return temp;
      });
    }
  }, [academicYearFrom, academicYearTo]);

  const handleClose = () => {
    setShow(false);
    handleExpand(false);
  };

  const handleActive = (value) => {
    if (value === "status") {
      setActiveStatus(true);
    } else if (value === "year") {
      setActiveYear(true);
    } else if (value === "term") {
      setActiveTerm(true);
    } else if (value === "EC") {
      setActiveEC(true);
    } else if (value === "Created By") {
      setActiveCreatedBy(true);
    } else if (value === "Student Name") {
      setActiveName(true);
    } else if (value === "sortBy") {
      setActiveSortBy(true);
    } else if (value === "Name") {
      setActiveName(true);
    } else if (value === "Email") {
      setActiveEmail(true);
    } else if (value === "ISCED") {
      setActiveISCED(true);
    } else if (value === "EQF") {
      setActiveEQF(true);
    } else {
      setActiveCountry(true);
    }
  };

  const getFilterValueForSelectField = (el) => {
    if (el === "" || el === null || el === undefined) {
      return null;
    } else {
      return [{ label: el }];
    }
  };

  const getFilterValueForMultiSelectField = (el) => {
    if (el === [] || el === null || el === undefined) {
      return null;
    } else {
      return el.map((i) => ({ label: i }));
    }
  };

  const setFilterFromSelect = (el, filter) => {
    if (el !== null && el !== undefined) {
      setSelectedValues((prevState) => {
        let temp = Object.assign({}, prevState);
        temp[filter] = el;
        return temp;
      });
    } else {
      setSelectedValues((prevState) => {
        let temp = Object.assign({}, prevState);
        temp[filter] = "";
        return temp;
      });
    }
  };

  if (loadingEcodes)
    return <div className="text-center mx-auto">Loading...</div>;
  if (errorEcodes)
    return (
      <div className="text-center text-danger mx-auto">An error occured</div>
    );

  return (
    <>
      <Offcanvas show={show} onHide={handleClose} placement={"end"}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title
            className="h4-style"
            style={{ color: "#000000", paddingLeft: 39 }}
          >
            Filters & Sorting
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body style={{ padding: 0, overflowX: "hidden" }}>
          <Row style={{ height: "93%" }}>
            <Col
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <SidebarMenu
                id="sidebar"
                expand={"xs"}
                className="padding-top width flex-grow-1"
              >
                <SidebarMenu.Collapse className="width h-100">
                  <SidebarMenu.Header className="padding-0"></SidebarMenu.Header>
                  <SidebarMenu.Body className="padding-0 h-100">
                    <SidebarMenu.Nav>
                      
                        <SidebarMenu.Sub
                          eventKey={-1}
                          style={{ marginBottom: 20 }}
                        >
                          <SidebarMenu.Sub.Toggle
                            id="toggle0"
                            style={
                              activeSortBy
                                ? {
                                    backgroundColor: "#73A6AD",
                                    color: "white",
                                    paddingLeft: 38,
                                    paddingRight: 38,
                                    zIndex: 0,
                                  }
                                : {
                                    backgroundColor: "white",
                                    color: "#212529",
                                    paddingLeft: 38,
                                    paddingRight: 38,
                                    zIndex: 0,
                                  }
                            }
                          >
                            <SidebarMenu.Nav.Title className="h6-style">
                              Sort By
                            </SidebarMenu.Nav.Title>
                            <SidebarMenu.Nav.Icon
                              className="margin-right"
                              style={
                                activeSortBy
                                  ? {
                                      filter:
                                        "invert(100%) sepia(100%) saturate(0%) hue-rotate(319deg) brightness(109%) contrast(102%)",
                                    }
                                  : {
                                      color:
                                        "filter: invert(38%) sepia(0%) saturate(570%) hue-rotate(162deg) brightness(107%) contrast(97%)",
                                    }
                              }
                            />
                          </SidebarMenu.Sub.Toggle>
                          <SidebarMenu.Sub.Collapse>
                            <SidebarMenu.Nav
                              style={{ backgroundColor: "#F2F3F6" }}
                            >
                              <div className="mx-auto w-75 mt-4 mb-4">
                                <Select
                                  value={getFilterValueForSelectField(
                                    OlaSortingMapping[selectedSortBy]
                                  )}
                                  onChange={(el) => {
                                    setSelectedSortBy(el?.value);
                                    el
                                      ? handleActive("sortBy")
                                      : setActiveSortBy(false);
                                  }}
                                  options={(type==="iia" || type==="pending-requests")? type === "iia" ? sortingList.filter(v=>v.value!=="name") : sortingList.filter(v=>v.value!=="name" && v.value !== "status") :sortingList}
                                  placeholder="Select sort by..."
                                />
                              </div>
                            </SidebarMenu.Nav>
                          </SidebarMenu.Sub.Collapse>
                        </SidebarMenu.Sub>
                      
                      {(type === "ola" || type==="short-term") ? (
                        <SidebarMenu.Sub eventKey={0}>
                          <SidebarMenu.Sub.Toggle
                            id="toggle1"
                            style={
                              activeStatus
                                ? {
                                    backgroundColor: "#3D619C",
                                    color: "white",
                                    paddingLeft: 38,
                                    paddingRight: 38,
                                    zIndex: 0,
                                  }
                                : {
                                    backgroundColor: "white",
                                    color: "#212529",
                                    paddingLeft: 38,
                                    paddingRight: 38,
                                    zIndex: 0,
                                  }
                            }
                          >
                            <SidebarMenu.Nav.Title className="h6-style">
                              Status
                            </SidebarMenu.Nav.Title>
                            <SidebarMenu.Nav.Icon
                              className="margin-right"
                              style={
                                activeStatus
                                  ? {
                                      filter:
                                        "invert(100%) sepia(100%) saturate(0%) hue-rotate(319deg) brightness(109%) contrast(102%)",
                                    }
                                  : {
                                      color:
                                        "filter: invert(38%) sepia(0%) saturate(570%) hue-rotate(162deg) brightness(107%) contrast(97%)",
                                    }
                              }
                            />
                          </SidebarMenu.Sub.Toggle>
                          <SidebarMenu.Sub.Collapse>
                            <SidebarMenu.Nav
                              style={{ backgroundColor: "#F2F3F6" }}
                            >
                              <div className="mx-auto w-75 mt-4 mb-4">
                                <Select
                                  value={getFilterValueForSelectField(
                                    StatusMapping[selectedValues["status"]]
                                  )}
                                  onChange={(el) => {
                                    setFilterFromSelect(el?.value, "status");
                                    el
                                      ? handleActive("status")
                                      : setActiveStatus(false);
                                  }}
                                  options={statuses_ola.map((el) => ({
                                    value: el,
                                    label: StatusMapping[el],
                                  }))}
                                  placeholder="Select status..."
                                />
                              </div>
                            </SidebarMenu.Nav>
                          </SidebarMenu.Sub.Collapse>
                        </SidebarMenu.Sub>
                      ) : (
                        (type === "nominations-incoming" ||
                          type === "nominations-outgoing") && (
                          <SidebarMenu.Sub eventKey={0}>
                            <SidebarMenu.Sub.Toggle
                              id="toggle1"
                              style={
                                activeStatus
                                  ? {
                                      backgroundColor: "#3D619C",
                                      color: "white",
                                      paddingLeft: 38,
                                      paddingRight: 38,
                                      zIndex: 0,
                                    }
                                  : {
                                      backgroundColor: "white",
                                      color: "#212529",
                                      paddingLeft: 38,
                                      paddingRight: 38,
                                      zIndex: 0,
                                    }
                              }
                            >
                              <SidebarMenu.Nav.Title className="h6-style">
                                Status
                              </SidebarMenu.Nav.Title>
                              <SidebarMenu.Nav.Icon
                                className="margin-right"
                                style={
                                  activeStatus
                                    ? {
                                        filter:
                                          "invert(100%) sepia(100%) saturate(0%) hue-rotate(319deg) brightness(109%) contrast(102%)",
                                      }
                                    : {
                                        color:
                                          "filter: invert(38%) sepia(0%) saturate(570%) hue-rotate(162deg) brightness(107%) contrast(97%)",
                                      }
                                }
                              />
                            </SidebarMenu.Sub.Toggle>
                            <SidebarMenu.Sub.Collapse>
                              <SidebarMenu.Nav
                                style={{ backgroundColor: "#F2F3F6" }}
                              >
                                <div className="mx-auto w-75 mt-4 mb-4">
                                  <Select
                                    value={getFilterValueForSelectField(
                                      StatusNomination[selectedValues["status"]]
                                    )}
                                    onChange={(el) => {
                                      setFilterFromSelect(el?.value, "status");
                                      el
                                        ? handleActive("status")
                                        : setActiveStatus(false);
                                    }}
                                    options={statuses_nominations.map((el) => ({
                                      value: el,
                                      label: StatusNomination[el],
                                    }))}
                                  />
                                </div>
                              </SidebarMenu.Nav>
                            </SidebarMenu.Sub.Collapse>
                          </SidebarMenu.Sub>
                        )
                      )}
                      {type === "iia" &&  <SidebarMenu.Sub eventKey={0}>
                          <SidebarMenu.Sub.Toggle
                            id="toggle1"
                            style={
                              activeStatus
                                ? {
                                    backgroundColor: "#3D619C",
                                    color: "white",
                                    paddingLeft: 38,
                                    paddingRight: 38,
                                    zIndex: 0,
                                  }
                                : {
                                    backgroundColor: "white",
                                    color: "#212529",
                                    paddingLeft: 38,
                                    paddingRight: 38,
                                    zIndex: 0,
                                  }
                            }
                          >
                            <SidebarMenu.Nav.Title className="h6-style">
                              Status
                            </SidebarMenu.Nav.Title>
                            <SidebarMenu.Nav.Icon
                              className="margin-right"
                              style={
                                activeStatus
                                  ? {
                                      filter:
                                        "invert(100%) sepia(100%) saturate(0%) hue-rotate(319deg) brightness(109%) contrast(102%)",
                                    }
                                  : {
                                      color:
                                        "filter: invert(38%) sepia(0%) saturate(570%) hue-rotate(162deg) brightness(107%) contrast(97%)",
                                    }
                              }
                            />
                          </SidebarMenu.Sub.Toggle>
                          <SidebarMenu.Sub.Collapse>
                            <SidebarMenu.Nav
                              style={{ backgroundColor: "#F2F3F6" }}
                            >
                              <div className="mx-auto w-75 mt-4 mb-4">
                                <Select
                                  value={getFilterValueForSelectField(
                                    StatusMapping[selectedValues["status"]]
                                  )}
                                  onChange={(el) => {
                                    setFilterFromSelect(el?.value, "status");
                                    el
                                      ? handleActive("status")
                                      : setActiveStatus(false);
                                  }}
                                  options={statuses_iia.map((el) => ({
                                    value: el,
                                    label: StatusMapping[el],
                                  }))}
                                  placeholder="Select status..."
                                />
                              </div>
                            </SidebarMenu.Nav>
                          </SidebarMenu.Sub.Collapse>
                        </SidebarMenu.Sub>}
                       {type==="iia"&& <SidebarMenu.Sub eventKey={1}>
                            <SidebarMenu.Sub.Toggle
                              id="toggle3"
                              style={
                                selectedValues?.partnerID
                                  ? {
                                      backgroundColor: "#3D619C",
                                      color: "white",
                                      paddingLeft: 38,
                                      paddingRight: 38,
                                    }
                                  : {
                                      backgroundColor: "white",
                                      color: "#212529",
                                      paddingLeft: 38,
                                      paddingRight: 38,
                                    }
                              }
                            >
                              <SidebarMenu.Nav.Title className="h6-style">
                              IIA ID
                              </SidebarMenu.Nav.Title>
                              <SidebarMenu.Nav.Icon
                                className="margin-right"
                                style={
                                  activeYear
                                    ? {
                                        filter:
                                          "invert(100%) sepia(100%) saturate(0%) hue-rotate(319deg) brightness(109%) contrast(102%)",
                                      }
                                    : {
                                        color:
                                          "filter: invert(38%) sepia(0%) saturate(570%) hue-rotate(162deg) brightness(107%) contrast(97%)",
                                      }
                                }
                              />
                            </SidebarMenu.Sub.Toggle>
                            <SidebarMenu.Sub.Collapse>
                              <SidebarMenu.Nav
                                style={{ backgroundColor: "#F2F3F6" }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    marginTop: 18,
                                    marginBottom: 22,
                                    paddingLeft: 28,
                                  }}
                                >
                                  <Row className="m-3" style={{ width: "85%" }}>
                                    <Col>
                                      <Form
                                        onSubmit={(e) => {
                                          e.preventDefault();
                                        }}
                                        noValidate
                                      >
                                        <Form.Group
                                          className="col-12"
                                          controlId="formContactEmail"
                                        >
                                          <Form.Label>Search by your ID or partner ID:</Form.Label>
                                          <Form.Control
                                            placeholder="ΙΙΑ ID"
                                            value={selectedValues?.partnerID}
                                            required
                                            onChange={(e) =>
                                              setSelectedValues(
                                                (prevState) => ({
                                                  ...prevState,
                                                  partnerID: e.target.value,
                                                })
                                              )
                                            }
                                          />
                                        </Form.Group>
                                      </Form>
                                    </Col>
                                  </Row>
                                </div>
                              </SidebarMenu.Nav>
                            </SidebarMenu.Sub.Collapse>
                          </SidebarMenu.Sub>}
                      {(type !== "pending-requests" && type !== "iia") && (
                        <SidebarMenu.Sub eventKey={1}>
                          <SidebarMenu.Sub.Toggle
                            id="toggle2"
                            style={
                              activeYear
                                ? {
                                    backgroundColor: "#3D619C",
                                    color: "white",
                                    paddingLeft: 38,
                                    paddingRight: 38,
                                    zIndex: 0,
                                  }
                                : {
                                    backgroundColor: "white",
                                    color: "#212529",
                                    paddingLeft: 38,
                                    paddingRight: 38,
                                    zIndex: 0,
                                  }
                            }
                          >
                            <SidebarMenu.Nav.Title className="h6-style">
                              Academic Year
                            </SidebarMenu.Nav.Title>
                            <SidebarMenu.Nav.Icon
                              className="margin-right"
                              style={
                                activeYear
                                  ? {
                                      filter:
                                        "invert(100%) sepia(100%) saturate(0%) hue-rotate(319deg) brightness(109%) contrast(102%)",
                                    }
                                  : {
                                      color:
                                        "filter: invert(38%) sepia(0%) saturate(570%) hue-rotate(162deg) brightness(107%) contrast(97%)",
                                    }
                              }
                            />
                          </SidebarMenu.Sub.Toggle>
                          <SidebarMenu.Sub.Collapse>
                            <SidebarMenu.Nav
                              style={{ backgroundColor: "#F2F3F6" }}
                            >
                              <div className="mx-auto w-75 mt-4 mb-4">
                                <Select
                                  value={getFilterValueForSelectField(
                                    selectedValues["academicYear"]
                                  )}
                                  onChange={(el) => {
                                    setFilterFromSelect(
                                      el?.value,
                                      "academicYear"
                                    );
                                    el
                                      ? handleActive("year")
                                      : setActiveStatus(false);
                                  }}
                                  options={academicYears.map((el) => ({
                                    value: el,
                                    label: el,
                                  }))}
                                  placeholder="Select academic year..."
                                />
                              </div>
                            </SidebarMenu.Nav>
                          </SidebarMenu.Sub.Collapse>
                        </SidebarMenu.Sub>
                      )}
                      {(type === "nominations-incoming" ||
                        type === "nominations-outgoing") && (
                        <>
                          <SidebarMenu.Sub eventKey={2}>
                            <SidebarMenu.Sub.Toggle
                              id="toggle3"
                              style={
                                activeTerm
                                  ? {
                                      backgroundColor: "#3D619C",
                                      color: "white",
                                      paddingLeft: 38,
                                      paddingRight: 38,
                                      zIndex: 0,
                                    }
                                  : {
                                      backgroundColor: "white",
                                      color: "#212529",
                                      paddingLeft: 38,
                                      paddingRight: 38,
                                      zIndex: 0,
                                    }
                              }
                            >
                              <SidebarMenu.Nav.Title className="h6-style">
                                Academic Term
                              </SidebarMenu.Nav.Title>
                              <SidebarMenu.Nav.Icon
                                className="margin-right"
                                style={
                                  activeTerm
                                    ? {
                                        filter:
                                          "invert(100%) sepia(100%) saturate(0%) hue-rotate(319deg) brightness(109%) contrast(102%)",
                                      }
                                    : {
                                        color:
                                          "filter: invert(38%) sepia(0%) saturate(570%) hue-rotate(162deg) brightness(107%) contrast(97%)",
                                      }
                                }
                              />
                            </SidebarMenu.Sub.Toggle>
                            <SidebarMenu.Sub.Collapse>
                              <SidebarMenu.Nav
                                style={{ backgroundColor: "#F2F3F6" }}
                              >
                                <div className="mx-auto w-75 mt-4 mb-4">
                                  <Select
                                    value={getFilterValueForSelectField(
                                      selectedValues["academicTerm"]
                                    )}
                                    onChange={(el) => {
                                      setFilterFromSelect(
                                        el?.value,
                                        "academicTerm"
                                      );
                                      el
                                        ? handleActive("term")
                                        : setActiveStatus(false);
                                    }}
                                    options={academicTerms.map((el) => ({
                                      value: el,
                                      label: el,
                                    }))}
                                  />
                                </div>
                              </SidebarMenu.Nav>
                            </SidebarMenu.Sub.Collapse>
                          </SidebarMenu.Sub>
                        </>
                      )}
                      {(type === "ola" || type === "short-term") && (
                        
                          <SidebarMenu.Sub eventKey={2}>
                            <SidebarMenu.Sub.Toggle
                              id="toggle3"
                              style={
                                activeCountry
                                  ? {
                                      backgroundColor: "#3D619C",
                                      color: "white",
                                      paddingLeft: 38,
                                      paddingRight: 38,
                                      zIndex: 0,
                                    }
                                  : {
                                      backgroundColor: "white",
                                      color: "#212529",
                                      paddingLeft: 38,
                                      paddingRight: 38,
                                      zIndex: 0,
                                    }
                              }
                            >
                              <SidebarMenu.Nav.Title className="h6-style">
                                Country
                              </SidebarMenu.Nav.Title>
                              <SidebarMenu.Nav.Icon
                                className="margin-right"
                                style={
                                  activeCountry
                                    ? {
                                        filter:
                                          "invert(100%) sepia(100%) saturate(0%) hue-rotate(319deg) brightness(109%) contrast(102%)",
                                      }
                                    : {
                                        color:
                                          "filter: invert(38%) sepia(0%) saturate(570%) hue-rotate(162deg) brightness(107%) contrast(97%)",
                                      }
                                }
                              />
                            </SidebarMenu.Sub.Toggle>
                            <SidebarMenu.Sub.Collapse>
                              <SidebarMenu.Nav
                                style={{ backgroundColor: "#F2F3F6" }}
                              >
                                <div className="mx-auto w-75 mt-4 mb-4">
                                  <MultiSelect
                                    value={getFilterValueForMultiSelectField(
                                      selectedValues["country"]
                                    )}
                                    onChange={(item) => {
                                      const tempCountries=item.map((i) => i.label);
                                      setFilterFromSelect(
                                        tempCountries,
                                        "country"
                                      );
                                      refetchEcodes({countryCodes:countries?.filter(c=>tempCountries?.includes(c?.CountryName))?.map(c=>c?.ID)});
                                      handleActive("country");
                                    }}
                                    options={countries?.map((country) => ({
                                      value: country.CountryName,
                                      label: country.CountryName,
                                    }))}
                                    placeholder="Select one or more countries..."
                                  />
                                </div>
                              </SidebarMenu.Nav>
                            </SidebarMenu.Sub.Collapse>
                          </SidebarMenu.Sub>
                          )}
                          {(type === "ola" || type === "short-term" || type === "nominations-incoming" || type==="iia" ||
                        type === "nominations-outgoing") &&
                          <SidebarMenu.Sub eventKey={3}>
                            <SidebarMenu.Sub.Toggle
                              id="toggle4"
                              style={
                                activeEC
                                  ? {
                                      backgroundColor: "#3D619C",
                                      color: "white",
                                      paddingLeft: 38,
                                      paddingRight: 38,
                                      zIndex: 0,
                                    }
                                  : {
                                      backgroundColor: "white",
                                      color: "#212529",
                                      paddingLeft: 38,
                                      paddingRight: 38,
                                      zIndex: 0,
                                    }
                              }
                            >
                              <SidebarMenu.Nav.Title className="h6-style">
                                Institution
                              </SidebarMenu.Nav.Title>
                              <SidebarMenu.Nav.Icon
                                className="margin-right"
                                style={
                                  activeEC
                                    ? {
                                        filter:
                                          "invert(100%) sepia(100%) saturate(0%) hue-rotate(319deg) brightness(109%) contrast(102%)",
                                      }
                                    : {
                                        color:
                                          "filter: invert(38%) sepia(0%) saturate(570%) hue-rotate(162deg) brightness(107%) contrast(97%)",
                                      }
                                }
                              />
                            </SidebarMenu.Sub.Toggle>
                            <SidebarMenu.Sub.Collapse>
                              <SidebarMenu.Nav
                                style={{ backgroundColor: "#F2F3F6" }}
                              >
                                <div className="mx-auto w-75 mt-4 mb-2">
                                <Form.Label>Erasmus Code:</Form.Label>
                                  <Select
                                    value={getFilterValueForSelectField(
                                      selectedValues["erasmusCode"]
                                    )}
                                    onChange={(el) => {
                                      setFilterFromSelect(
                                        el?.value,
                                        "erasmusCode"
                                      );
                                      el
                                        ? handleActive("EC")
                                        : setActiveEC(false);
                                    }}
                                    options={ecodes?.heis?.map((el) => ({
                                            value: el?.erasmusCode,
                                            label: el?.erasmusCode,
                                          }))}
                                    placeholder="Select erasmus code..."
                                  />
                                </div>
                                <div className="mx-auto w-75 mt-4 mb-4">
                                <Form.Label>Institution Name:</Form.Label>
                                  <Select
                                    value={getFilterValueForSelectField(
                                      ecodes?.heis?.find(i=>i?.erasmusCode===selectedValues["erasmusCode"])?.name
                                    )}
                                    onChange={(el) => {
                                      setFilterFromSelect(
                                        ecodes?.heis?.find(i=>i?.name===el?.value)?.erasmusCode,
                                        "erasmusCode"
                                      );
                                      el
                                        ? handleActive("EC")
                                        : setActiveEC(false);
                                    }}
                                    options={ecodes?.heis?.map((el) => ({
                                            value: el?.name,
                                            label: el?.name,
                                          }))}
                                    placeholder="Select institution name..."
                                  />
                                </div>
                              </SidebarMenu.Nav>
                            </SidebarMenu.Sub.Collapse>
                          </SidebarMenu.Sub>}
                          {type==="iia"&& <SidebarMenu.Sub eventKey={2}>
                            <SidebarMenu.Sub.Toggle
                              id="toggle3"
                              style={
                                selectedValues?.approvingPersonEmail
                                  ? {
                                      backgroundColor: "#3D619C",
                                      color: "white",
                                      paddingLeft: 38,
                                      paddingRight: 38,
                                    }
                                  : {
                                      backgroundColor: "white",
                                      color: "#212529",
                                      paddingLeft: 38,
                                      paddingRight: 38,
                                    }
                              }
                            >
                              <SidebarMenu.Nav.Title className="h6-style">
                              Approving Person Email
                              </SidebarMenu.Nav.Title>
                              <SidebarMenu.Nav.Icon
                                className="margin-right"
                                style={
                                  activeYear
                                    ? {
                                        filter:
                                          "invert(100%) sepia(100%) saturate(0%) hue-rotate(319deg) brightness(109%) contrast(102%)",
                                      }
                                    : {
                                        color:
                                          "filter: invert(38%) sepia(0%) saturate(570%) hue-rotate(162deg) brightness(107%) contrast(97%)",
                                      }
                                }
                              />
                            </SidebarMenu.Sub.Toggle>
                            <SidebarMenu.Sub.Collapse>
                              <SidebarMenu.Nav
                                style={{ backgroundColor: "#F2F3F6" }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    marginTop: 18,
                                    marginBottom: 22,
                                    paddingLeft: 28,
                                  }}
                                >
                                  <Row className="m-3" style={{ width: "85%" }}>
                                    <Col>
                                      <Form
                                        onSubmit={(e) => {
                                          e.preventDefault();
                                        }}
                                        noValidate
                                      >
                                        <Form.Group
                                          className="col-12"
                                          controlId="formContactEmail"
                                        >
                                          <Form.Control
                                            type="email"
                                            placeholder="Approving Person Email"
                                            value={selectedValues?.approvingPersonEmail}
                                            required
                                            onChange={(e) =>
                                              setSelectedValues(
                                                (prevState) => ({
                                                  ...prevState,
                                                  approvingPersonEmail: e.target.value,
                                                })
                                              )
                                            }
                                          />
                                        </Form.Group>
                                      </Form>
                                    </Col>
                                  </Row>
                                </div>
                              </SidebarMenu.Nav>
                            </SidebarMenu.Sub.Collapse>
                          </SidebarMenu.Sub>}
                          {type === "ola"&&<SidebarMenu.Sub eventKey={4}>
                            <SidebarMenu.Sub.Toggle
                              id="toggle5"
                              style={
                                activeCreatedBy
                                  ? {
                                      backgroundColor: "#3D619C",
                                      color: "white",
                                      paddingLeft: 38,
                                      paddingRight: 38,
                                      zIndex: 0,
                                    }
                                  : {
                                      backgroundColor: "white",
                                      color: "#212529",
                                      paddingLeft: 38,
                                      paddingRight: 38,
                                      zIndex: 0,
                                    }
                              }
                            >
                              <SidebarMenu.Nav.Title className="h6-style">
                                Created By
                              </SidebarMenu.Nav.Title>
                              <SidebarMenu.Nav.Icon
                                className="margin-right"
                                style={
                                  activeCreatedBy
                                    ? {
                                        filter:
                                          "invert(100%) sepia(100%) saturate(0%) hue-rotate(319deg) brightness(109%) contrast(102%)",
                                      }
                                    : {
                                        color:
                                          "filter: invert(38%) sepia(0%) saturate(570%) hue-rotate(162deg) brightness(107%) contrast(97%)",
                                      }
                                }
                              />
                            </SidebarMenu.Sub.Toggle>
                            <SidebarMenu.Sub.Collapse>
                              <SidebarMenu.Nav
                                style={{ backgroundColor: "#F2F3F6" }}
                              >
                                <div className="mx-auto w-75 mt-4 mb-4">
                                  <Select
                                    value={getFilterValueForSelectField(
                                      selectedValues["createdByStudent"]
                                    )}
                                    onChange={(el) => {
                                      setFilterFromSelect(
                                        el?.value,
                                        "createdByStudent"
                                      );
                                      el
                                        ? handleActive("Created By")
                                        : setActiveCreatedBy(false);
                                    }}
                                    options={createdBy.map((el) => ({
                                      value: el,
                                      label: el,
                                    }))}
                                    placeholder="Select created by..."
                                  />
                                </div>
                              </SidebarMenu.Nav>
                            </SidebarMenu.Sub.Collapse>
                          </SidebarMenu.Sub>}
                          {(type === "ola" || type === "short-term") && <SidebarMenu.Sub eventKey={5}>
                            <SidebarMenu.Sub.Toggle
                              id="toggle6"
                              style={
                                activeName
                                  ? {
                                      backgroundColor: "#3D619C",
                                      color: "white",
                                      paddingLeft: 38,
                                      paddingRight: 38,
                                      zIndex: 0,
                                    }
                                  : {
                                      backgroundColor: "white",
                                      color: "#212529",
                                      paddingLeft: 38,
                                      paddingRight: 38,
                                      zIndex: 0,
                                    }
                              }
                            >
                              <SidebarMenu.Nav.Title className="h6-style">
                                Student Name
                              </SidebarMenu.Nav.Title>
                              <SidebarMenu.Nav.Icon
                                className="margin-right"
                                style={
                                  activeName
                                    ? {
                                        filter:
                                          "invert(100%) sepia(100%) saturate(0%) hue-rotate(319deg) brightness(109%) contrast(102%)",
                                      }
                                    : {
                                        color:
                                          "filter: invert(38%) sepia(0%) saturate(570%) hue-rotate(162deg) brightness(107%) contrast(97%)",
                                      }
                                }
                              />
                            </SidebarMenu.Sub.Toggle>
                            <SidebarMenu.Sub.Collapse>
                              <SidebarMenu.Nav
                                style={{ backgroundColor: "#F2F3F6" }}
                              >
                                <div className="mx-auto w-75 mt-4 mb-4">
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formBasicEmail"
                                  >
                                    <Form.Label>First Name:</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="First Name"
                                      onChange={(el) => {
                                        setFilterFromSelect(
                                          el.target.value,
                                          "studentFirstName"
                                        );
                                      }}
                                      value={selectedValues["studentFirstName"]}
                                    />
                                  </Form.Group>
                                </div>
                                <div className="mx-auto w-75 mb-4">
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formBasicEmail"
                                  >
                                    <Form.Label>Last Name:</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Last Name"
                                      value={selectedValues["studentLastName"]}
                                      onChange={(el) => {
                                        setFilterFromSelect(
                                          el.target.value,
                                          "studentLastName"
                                        );
                                      }}
                                    />
                                  </Form.Group>
                                </div>
                              </SidebarMenu.Nav>
                            </SidebarMenu.Sub.Collapse>
                          </SidebarMenu.Sub>
                        
                      }
                      {(type === "nominations-incoming" ||
                        type === "nominations-outgoing") && (
                        <>
                          <SidebarMenu.Sub eventKey={5}>
                            <SidebarMenu.Sub.Toggle
                              id="toggle6"
                              style={
                                activeName
                                  ? {
                                      backgroundColor: "#3D619C",
                                      color: "white",
                                      paddingLeft: 38,
                                      paddingRight: 38,
                                      zIndex: 0,
                                    }
                                  : {
                                      backgroundColor: "white",
                                      color: "#212529",
                                      paddingLeft: 38,
                                      paddingRight: 38,
                                      zIndex: 0,
                                    }
                              }
                            >
                              <SidebarMenu.Nav.Title className="h6-style">
                                Name
                              </SidebarMenu.Nav.Title>
                              <SidebarMenu.Nav.Icon
                                className="margin-right"
                                style={
                                  activeName
                                    ? {
                                        filter:
                                          "invert(100%) sepia(100%) saturate(0%) hue-rotate(319deg) brightness(109%) contrast(102%)",
                                      }
                                    : {
                                        color:
                                          "filter: invert(38%) sepia(0%) saturate(570%) hue-rotate(162deg) brightness(107%) contrast(97%)",
                                      }
                                }
                              />
                            </SidebarMenu.Sub.Toggle>
                            <SidebarMenu.Sub.Collapse>
                              <SidebarMenu.Nav
                                style={{ backgroundColor: "#F2F3F6" }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    marginTop: 18,
                                    marginBottom: 22,
                                    paddingLeft: 28,
                                  }}
                                >
                                  <Row className="m-3" style={{ width: "85%" }}>
                                    <Col>
                                      <Form
                                        onSubmit={(e) => {
                                          e.preventDefault();
                                        }}
                                        noValidate
                                      >
                                        <Form.Group
                                          className="col-12"
                                          controlId="formBasicName"
                                        >
                                          <Form.Control
                                            type="text"
                                            placeholder="Name"
                                            value={
                                              selectedValues["studentName"]
                                            }
                                            required
                                            onChange={(el) => {
                                              setFilterFromSelect(
                                                el.target.value,
                                                "studentName"
                                              );
                                              el.target.value
                                                ? handleActive("Name")
                                                : setActiveEmail(false);
                                            }}
                                          />
                                        </Form.Group>
                                      </Form>
                                    </Col>
                                  </Row>
                                </div>
                              </SidebarMenu.Nav>
                            </SidebarMenu.Sub.Collapse>
                          </SidebarMenu.Sub>
                          <SidebarMenu.Sub eventKey={6}>
                            <SidebarMenu.Sub.Toggle
                              id="toggle7"
                              style={
                                activeEmail
                                  ? {
                                      backgroundColor: "#3D619C",
                                      color: "white",
                                      paddingLeft: 38,
                                      paddingRight: 38,
                                      zIndex: 0,
                                    }
                                  : {
                                      backgroundColor: "white",
                                      color: "#212529",
                                      paddingLeft: 38,
                                      paddingRight: 38,
                                      zIndex: 0,
                                    }
                              }
                            >
                              <SidebarMenu.Nav.Title className="h6-style">
                                Email
                              </SidebarMenu.Nav.Title>
                              <SidebarMenu.Nav.Icon
                                className="margin-right"
                                style={
                                  activeEmail
                                    ? {
                                        filter:
                                          "invert(100%) sepia(100%) saturate(0%) hue-rotate(319deg) brightness(109%) contrast(102%)",
                                      }
                                    : {
                                        color:
                                          "filter: invert(38%) sepia(0%) saturate(570%) hue-rotate(162deg) brightness(107%) contrast(97%)",
                                      }
                                }
                              />
                            </SidebarMenu.Sub.Toggle>
                            <SidebarMenu.Sub.Collapse>
                              <SidebarMenu.Nav
                                style={{ backgroundColor: "#F2F3F6" }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    marginTop: 18,
                                    marginBottom: 22,
                                    paddingLeft: 28,
                                  }}
                                >
                                  <Row className="m-3" style={{ width: "85%" }}>
                                    <Col>
                                      <Form
                                        onSubmit={(e) => {
                                          e.preventDefault();
                                        }}
                                        validated={true}
                                        noValidate
                                      >
                                        <Form.Group
                                          className="col-12"
                                          controlId="formBasicEmail"
                                        >
                                          <Form.Control
                                            type="email"
                                            placeholder="Email"
                                            value={
                                              selectedValues["studentEmail"]
                                            }
                                            required
                                            onChange={(el) => {
                                              setFilterFromSelect(
                                                el.target.value,
                                                "studentEmail"
                                              );
                                              el.target.value
                                                ? handleActive("Email")
                                                : setActiveEmail(false);
                                            }}
                                          />
                                          <Form.Control.Feedback type="invalid">
                                            Please provide a valid value prompt.
                                          </Form.Control.Feedback>
                                        </Form.Group>
                                      </Form>
                                    </Col>
                                  </Row>
                                </div>
                              </SidebarMenu.Nav>
                            </SidebarMenu.Sub.Collapse>
                          </SidebarMenu.Sub>
                          {/* <SidebarMenu.Sub eventKey={7}>
                            <SidebarMenu.Sub.Toggle
                              id="toggle8"
                              style={
                                activeISCED
                                  ? {
                                      backgroundColor: "#3D619C",
                                      color: "white",
                                      paddingLeft: 38,
                                      paddingRight: 38,
                                      zIndex: 0,
                                    }
                                  : {
                                      backgroundColor: "white",
                                      color: "#212529",
                                      paddingLeft: 38,
                                      paddingRight: 38,
                                      zIndex: 0,
                                    }
                              }
                            >
                              <SidebarMenu.Nav.Title className="h6-style">
                                Student Field (ISCED)
                              </SidebarMenu.Nav.Title>
                              <SidebarMenu.Nav.Icon
                                className="margin-right"
                                style={
                                  activeISCED
                                    ? {
                                        filter:
                                          "invert(100%) sepia(100%) saturate(0%) hue-rotate(319deg) brightness(109%) contrast(102%)",
                                      }
                                    : {
                                        color:
                                          "filter: invert(38%) sepia(0%) saturate(570%) hue-rotate(162deg) brightness(107%) contrast(97%)",
                                      }
                                }
                              />
                            </SidebarMenu.Sub.Toggle>
                            <SidebarMenu.Sub.Collapse>
                              <SidebarMenu.Nav
                                style={{ backgroundColor: "#F2F3F6" }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    marginTop: 18,
                                    marginBottom: 22,
                                    paddingLeft: 28,
                                  }}
                                >
                                  <Row className="m-3" style={{ width: "85%" }}>
                                    <Col>
                                      <Form
                                        onSubmit={(e) => {
                                          e.preventDefault();
                                        }}
                                        noValidate
                                      >
                                        <Form.Group
                                          className="col-12"
                                          controlId="formBasicISCED"
                                        >
                                          <Form.Control
                                            type="text"
                                            placeholder="ISCED"
                                            value={
                                              selectedValues["studentISCED"]
                                            }
                                            required
                                            onChange={(el) => {
                                              setFilterFromSelect(
                                                el.target.value,
                                                "studentISCED"
                                              );
                                              el.target.value
                                                ? handleActive("ISCED")
                                                : setActiveISCED(false);
                                            }}
                                          />
                                        </Form.Group>
                                      </Form>
                                    </Col>
                                  </Row>
                                </div>
                              </SidebarMenu.Nav>
                            </SidebarMenu.Sub.Collapse>
                          </SidebarMenu.Sub>
                          <SidebarMenu.Sub eventKey={8}>
                            <SidebarMenu.Sub.Toggle
                              id="toggle9"
                              style={
                                activeEQF
                                  ? {
                                      backgroundColor: "#3D619C",
                                      color: "white",
                                      paddingLeft: 38,
                                      paddingRight: 38,
                                      zIndex: 0,
                                    }
                                  : {
                                      backgroundColor: "white",
                                      color: "#212529",
                                      paddingLeft: 38,
                                      paddingRight: 38,
                                      zIndex: 0,
                                    }
                              }
                            >
                              <SidebarMenu.Nav.Title className="h6-style">
                                Student Level (EQF)
                              </SidebarMenu.Nav.Title>
                              <SidebarMenu.Nav.Icon
                                className="margin-right"
                                style={
                                  activeEQF
                                    ? {
                                        filter:
                                          "invert(100%) sepia(100%) saturate(0%) hue-rotate(319deg) brightness(109%) contrast(102%)",
                                      }
                                    : {
                                        color:
                                          "filter: invert(38%) sepia(0%) saturate(570%) hue-rotate(162deg) brightness(107%) contrast(97%)",
                                      }
                                }
                              />
                            </SidebarMenu.Sub.Toggle>
                            <SidebarMenu.Sub.Collapse>
                              <SidebarMenu.Nav
                                style={{ backgroundColor: "#F2F3F6" }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    marginTop: 18,
                                    marginBottom: 22,
                                    paddingLeft: 28,
                                  }}
                                >
                                  <Row className="m-3" style={{ width: "85%" }}>
                                    <Col>
                                      <Form
                                        onSubmit={(e) => {
                                          e.preventDefault();
                                        }}
                                        noValidate
                                      >
                                        <Form.Group
                                          className="col-12"
                                          controlId="formBasicEQF"
                                        >
                                          <Form.Control
                                            type="text"
                                            placeholder="EQF"
                                            value={selectedValues["studentEQF"]}
                                            required
                                            onChange={(el) => {
                                              setFilterFromSelect(
                                                el.target.value,
                                                "studentEQF"
                                              );
                                              el.target.value
                                                ? handleActive("EQF")
                                                : setActiveEQF(false);
                                            }}
                                          />
                                        </Form.Group>
                                      </Form>
                                    </Col>
                                  </Row>
                                </div>
                              </SidebarMenu.Nav>
                            </SidebarMenu.Sub.Collapse>
                          </SidebarMenu.Sub> */}
                        </>
                      )}
                      {type === "pending-requests" && (
                        <>
                          <SidebarMenu.Sub eventKey={1}>
                            <SidebarMenu.Sub.Toggle
                              id="toggle2"
                              style={
                                selectedValues?.institutionName
                                  ? {
                                      backgroundColor: "#3D619C",
                                      color: "white",
                                      paddingLeft: 38,
                                      paddingRight: 38,
                                    }
                                  : {
                                      backgroundColor: "white",
                                      color: "#212529",
                                      paddingLeft: 38,
                                      paddingRight: 38,
                                    }
                              }
                            >
                              <SidebarMenu.Nav.Title className="h6-style">
                                Institution Name
                              </SidebarMenu.Nav.Title>
                              <SidebarMenu.Nav.Icon
                                className="margin-right"
                                style={
                                  ""
                                    ? {
                                        filter:
                                          "invert(100%) sepia(100%) saturate(0%) hue-rotate(319deg) brightness(109%) contrast(102%)",
                                      }
                                    : {
                                        color:
                                          "filter: invert(38%) sepia(0%) saturate(570%) hue-rotate(162deg) brightness(107%) contrast(97%)",
                                      }
                                }
                              />
                            </SidebarMenu.Sub.Toggle>
                            <SidebarMenu.Sub.Collapse>
                              <SidebarMenu.Nav
                                style={{ backgroundColor: "#F2F3F6" }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    marginTop: 18,
                                    marginBottom: 22,
                                    paddingLeft: 28,
                                  }}
                                >
                                  <Row className="m-3" style={{ width: "85%" }}>
                                    <Col>
                                      <Form
                                        onSubmit={(e) => {
                                          e.preventDefault();
                                        }}
                                        noValidate
                                      >
                                        <Form.Group
                                          className="col-12"
                                          controlId="formInstitutionName"
                                        >
                                          <Form.Control
                                            type="text"
                                            placeholder="Institution Name"
                                            value={
                                              selectedValues?.institutionName
                                            }
                                            required
                                            onChange={(e) =>
                                              setSelectedValues(
                                                (prevState) => ({
                                                  ...prevState,
                                                  institutionName:
                                                    e.target.value,
                                                })
                                              )
                                            }
                                          />
                                        </Form.Group>
                                      </Form>
                                    </Col>
                                  </Row>
                                </div>
                              </SidebarMenu.Nav>
                            </SidebarMenu.Sub.Collapse>
                          </SidebarMenu.Sub>
                          <SidebarMenu.Sub eventKey={2}>
                            <SidebarMenu.Sub.Toggle
                              id="toggle3"
                              style={
                                selectedValues?.contactEmail
                                  ? {
                                      backgroundColor: "#3D619C",
                                      color: "white",
                                      paddingLeft: 38,
                                      paddingRight: 38,
                                    }
                                  : {
                                      backgroundColor: "white",
                                      color: "#212529",
                                      paddingLeft: 38,
                                      paddingRight: 38,
                                    }
                              }
                            >
                              <SidebarMenu.Nav.Title className="h6-style">
                                Contact Email
                              </SidebarMenu.Nav.Title>
                              <SidebarMenu.Nav.Icon
                                className="margin-right"
                                style={
                                  activeYear
                                    ? {
                                        filter:
                                          "invert(100%) sepia(100%) saturate(0%) hue-rotate(319deg) brightness(109%) contrast(102%)",
                                      }
                                    : {
                                        color:
                                          "filter: invert(38%) sepia(0%) saturate(570%) hue-rotate(162deg) brightness(107%) contrast(97%)",
                                      }
                                }
                              />
                            </SidebarMenu.Sub.Toggle>
                            <SidebarMenu.Sub.Collapse>
                              <SidebarMenu.Nav
                                style={{ backgroundColor: "#F2F3F6" }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    marginTop: 18,
                                    marginBottom: 22,
                                    paddingLeft: 28,
                                  }}
                                >
                                  <Row className="m-3" style={{ width: "85%" }}>
                                    <Col>
                                      <Form
                                        onSubmit={(e) => {
                                          e.preventDefault();
                                        }}
                                        noValidate
                                      >
                                        <Form.Group
                                          className="col-12"
                                          controlId="formContactEmail"
                                        >
                                          <Form.Control
                                            type="email"
                                            placeholder="Contact Email"
                                            value={selectedValues?.contactEmail}
                                            required
                                            onChange={(e) =>
                                              setSelectedValues(
                                                (prevState) => ({
                                                  ...prevState,
                                                  contactEmail: e.target.value,
                                                })
                                              )
                                            }
                                          />
                                        </Form.Group>
                                      </Form>
                                    </Col>
                                  </Row>
                                </div>
                              </SidebarMenu.Nav>
                            </SidebarMenu.Sub.Collapse>
                          </SidebarMenu.Sub>
                          <SidebarMenu.Sub eventKey={3}>
                            <SidebarMenu.Sub.Toggle
                              id="toggle4"
                              style={
                                selectedValues?.status
                                  ? {
                                      backgroundColor: "#3D619C",
                                      color: "white",
                                      paddingLeft: 38,
                                      paddingRight: 38,
                                    }
                                  : {
                                      backgroundColor: "white",
                                      color: "#212529",
                                      paddingLeft: 38,
                                      paddingRight: 38,
                                    }
                              }
                            >
                              <SidebarMenu.Nav.Title className="h6-style">
                                Status
                              </SidebarMenu.Nav.Title>
                              <SidebarMenu.Nav.Icon
                                className="margin-right"
                                style={
                                  selectedValues?.status
                                    ? {
                                        filter:
                                          "invert(100%) sepia(100%) saturate(0%) hue-rotate(319deg) brightness(109%) contrast(102%)",
                                      }
                                    : {
                                        color:
                                          "filter: invert(38%) sepia(0%) saturate(570%) hue-rotate(162deg) brightness(107%) contrast(97%)",
                                      }
                                }
                              />
                            </SidebarMenu.Sub.Toggle>
                            <SidebarMenu.Sub.Collapse>
                              <SidebarMenu.Nav
                                style={{ backgroundColor: "#F2F3F6" }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    marginTop: 18,
                                    marginBottom: 22,
                                    paddingLeft: 28,
                                  }}
                                >
                                  <Row className="m-3" style={{ width: "85%" }}>
                                    <Col>
                                      <Form
                                        onSubmit={(e) => {
                                          e.preventDefault();
                                        }}
                                        noValidate
                                      >
                                        <Form.Group
                                          className="col-12"
                                          controlId="formStatus"
                                        >
                                          <Select
                                            options={pendingRequestsOptions}
                                            placeholder="Status"
                                            onChange={(newSelectOption) => {
                                              setSelectedValues(
                                                (prevState) => ({
                                                  ...prevState,
                                                  status: newSelectOption.value,
                                                })
                                              );
                                            }}
                                            value={
                                              selectedValues?.status !== ""
                                                ? {
                                                    label:
                                                      pendingRequestsOptions.find(
                                                        (status) =>
                                                          status.value ===
                                                          selectedValues?.status
                                                      ).label,
                                                    value:
                                                      selectedValues?.status,
                                                  }
                                                : null
                                            }
                                          />
                                        </Form.Group>
                                      </Form>
                                    </Col>
                                  </Row>
                                </div>
                              </SidebarMenu.Nav>
                            </SidebarMenu.Sub.Collapse>
                          </SidebarMenu.Sub>
                        </>
                      )}
                    </SidebarMenu.Nav>
                  </SidebarMenu.Body>
                  <SidebarMenu.Footer></SidebarMenu.Footer>
                </SidebarMenu.Collapse>
              </SidebarMenu>
              <div className="wrapper-buttons">
                <CustomButton
                  buttonType={"outlined"}
                  text={"Clear All"}
                  styles={{ marginRight: 24 }}
                  handleClick={() => {
                    type === "ola"
                      ? setSelectedFiltersList({
                          erasmusCode: "",
                          status: "",
                          country: [],
                          academicYear: "",
                          studentFirstName: "",
                          studentLastName: "",
                        })
                      : type === "nominations-incoming" ||
                        type === "nominations-outgoing"
                      ? setSelectedFiltersList({
                          erasmusCode: "",
                          status: "",
                          academicTerm: "",
                          academicYear: "",
                          studentName: "",
                          studentEQF: "",
                          studentISCED: "",
                          studentEmail: "",
                        })
                      : type === "iia" ?
                      setSelectedFiltersList({
                        partnerID: "",
                        status: "",
                        partnerHeiId: "",
                        approvingPersonEmail: "",
                      })
                      :setSelectedFiltersList({
                          institutionName: "",
                          contactEmail: "",
                          status: "",
                        });
                    setSortBy("");

                    if (type === "pending-requests") setPage(0);

                    handleClose();
                  }}
                ></CustomButton>
                <CustomButton
                  buttonType={"accept"}
                  text={"Apply Filters"}
                  handleClick={() => {
                    let partnerHeiID=ecodes?.heis?.find(i=>i?.erasmusCode===selectedValues["erasmusCode"])?.heiID;
                    setSelectedFiltersList(
                      type === "pending-requests"
                        ? {
                            institutionName: selectedValues.institutionName,
                            contactEmail: selectedValues.contactEmail,
                            status: selectedValues.status,
                          }
                        : type === "iia"?
                        {
                          ...selectedValues,
                          partnerHeiId: partnerHeiID?partnerHeiID:""
                        }
                        : selectedValues
                    );
                    setSortBy(selectedSortBy);

                    if (type === "pending-requests") setPage(0);

                    handleClose();
                  }}
                ></CustomButton>
              </div>
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};
export default Filters;
