import CustomTable from "../../../../../components/Table";
import IconButton from "../../../../../components/IconButton";
import { useEffect, useRef, useState } from "react";
import CustomModal from "../../../../../components/CustomModal";
import { faPen, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Form, Row, Col, Spinner, Tabs } from "react-bootstrap";
import CustomButton from "../../../../../components/CustomButton";
import COLORS from "../../../../../data/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCheck,
    faTrash,
    faFloppyDisk,
    faXmark
} from "@fortawesome/free-solid-svg-icons";
import { useQuery, useMutation } from '@apollo/client/react';
import ADD_TOR_COMPONENT_TABLE_C from "../../../../../graphql/mutations/ola/addToRComponentTableC";
import ADD_TOR_COMPONENT_TABLE_D from "../../../../../graphql/mutations/ola/addToRComponentTableD";
import GET_TOR from "../../../../../graphql/queries/ola/getTor";
import UPDATE_TOR_COMPONENT_TABLE_C from "../../../../../graphql/mutations/ola/updateToRComponentTableC";
import UPDATE_TOR_COMPONENT_TABLE_D from "../../../../../graphql/mutations/ola/updateToRComponentTableD";
import DELETE_TOR_COMPONENT_TABLE_C from "../../../../../graphql/mutations/ola/deleteToRComponentTableC";
import DELETE_TOR_COMPONENT_TABLE_D from "../../../../../graphql/mutations/ola/deleteToRComponentTableD";
import CREATE_TOR from "../../../../../graphql/mutations/ola/createToR";
import UPDATE_TOR from "../../../../../graphql/mutations/ola/updateToR";
import DatePicker from "react-datepicker";
import CustomTab from "../../../../../components/CustomTab";


const TorTable = ({ type, laId, startDate, endDate, tableA, tableA2, tableB, tableB2, toast, toastConfiguration, shouldDisplayTor }) => {
    const [showEdit, setShowEdit] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [showUpdateTor, setShowUpdateTor] = useState(false);
    const [createdTor, setCreatedTor] = useState(false);

    const [updated, setUpdated] = useState(false);
    const [componentId, setComponentId] = useState("");
    const [code, setCode] = useState("");
    const [title, setTitle] = useState("");
    const [grade, setGrade] = useState("");
    const [ects, setEcts] = useState("");
    const [completed, setCompleted] = useState(false);
    const [editStartDate, setEditStartDate] = useState("");
    const [editEndDate, setEditEndDate] = useState("");

    const [validated, setValidated] = useState(false);
    const [currentIdx, setCurrentIdx] = useState(null);
    const [dataTable, setDataTable] = useState([]);

    const formRef = useRef(null);

    const { loading: loadingTor, data, error: errorTor, refetch: refetchTor } = useQuery(GET_TOR, {
        variables: {
            laID: laId
        },
        onCompleted: (d) => {
            setEditStartDate(d?.tor?.field_study_period_start);
            setEditEndDate(d?.tor?.field_study_period_end)
        },
        notifyOnNetworkStatusChange: true
    });

    const [
        createTorMutation,
        {
            loading: loadingCreateTor
        },
    ] = useMutation(CREATE_TOR);


    const [
        updateTorMutation,
        {
            loading: loadingUpdateTor
        },
    ] = useMutation(UPDATE_TOR);

    const [
        addTorTableCMutation,
        {
            loading: loadingAddTorTableC
        },
    ] = useMutation(ADD_TOR_COMPONENT_TABLE_C);


    const [
        addTorTableDMutation,
        {
            loading: loadingAddTorTableD
        },
    ] = useMutation(ADD_TOR_COMPONENT_TABLE_D);


    const [
        updateTorTableCMutation,
        {
            loading: loadingUpdateTorTableC
        },
    ] = useMutation(UPDATE_TOR_COMPONENT_TABLE_C);


    const [
        updateTorTableDMutation,
        {
            loading: loadingUpdateTorTableD
        },
    ] = useMutation(UPDATE_TOR_COMPONENT_TABLE_D);


    const [
        deleteTorTableCMutation,
        {
            loading: loadingDeleteTorTableC
        },
    ] = useMutation(DELETE_TOR_COMPONENT_TABLE_C);


    const [
        deleteTorTableDMutation,
        {
            loading: loadingDeleteTorTableD
        },
    ] = useMutation(DELETE_TOR_COMPONENT_TABLE_D);


    useEffect(() => {
        if (currentIdx !== null) {
            if (type === "tableC") {
                setComponentId(data?.tor?.field_tor_table_c[currentIdx]?.id);
                setCode(data?.tor?.field_tor_table_c[currentIdx]?.field_tor_table_c_code);
                setTitle(data?.tor?.field_tor_table_c[currentIdx]?.field_tor_table_c_title);
                setGrade(data?.tor?.field_tor_table_c[currentIdx]?.field_tor_table_c_grade);
                setEcts(data?.tor?.field_tor_table_c[currentIdx]?.field_tor_table_c_ects);
                setCompleted(data?.tor?.field_tor_table_c[currentIdx]?.field_tor_table_c_completed);
            }
            else {
                setComponentId(data?.tor?.field_tor_table_d[currentIdx]?.id);
                setCode(data?.tor?.field_tor_table_d[currentIdx]?.field_tor_table_d_code);
                setTitle(data?.tor?.field_tor_table_d[currentIdx]?.field_tor_table_d_title);
                setGrade(data?.tor?.field_tor_table_d[currentIdx]?.field_tor_table_d_grade);
                setEcts(data?.tor?.field_tor_table_d[currentIdx]?.field_tor_table_d_ects);
            }
        }
        else {
            setComponentId("");
            setCode("");
            setTitle("");
            setGrade(null);
            setEcts("");
            setCompleted(false);
        }
    }, [currentIdx])

    const handleCloseModalAddTor = () => {
        setShowAdd(false);
        setCurrentIdx(null); 
    }
    const handleCloseModalEditTor = () => {
        setShowEdit(false);
        setCurrentIdx(null); 
    }
    const handleCloseModalDeleteTor = () => setShowDelete(false);
    const handleCloseModalUpdateteTor = () => setShowUpdateTor(false);

    useEffect(() => {
        if (!loadingTor && data && data?.hasOwnProperty("tor")) {
            setDataTable(dataToRender(type));
            shouldDisplayTor(true);
            
        }
        if (!loadingTor && !data?.tor){
            shouldDisplayTor(false);
        }
     
    }, [data?.tor?.field_tor_table_c, data?.tor?.field_tor_table_d, loadingTor]);

    useEffect(() => {
        setUpdated(true);
        setCurrentIdx(null);
    }, [dataTable])

    useEffect(() => {
        const createTableC = async () => {
            const tableC = calculateTableC();
            for (let c in tableC) {
                // const toastId = toast.loading("Creating a new component...", toastConfiguration);

                try {

                    let res = await addTorTableCMutation({
                        "variables": {
                            "torID": data?.tor?.id,
                            "componentCode": tableC[c]?.code,
                            "componentTitle": tableC[c]?.title,
                            "ects": tableC[c]?.ects,
                        }
                    });


                    if (!res?.errors) {
                        // toast.update(toastId, {
                        //     ...toastConfiguration,
                        //     render: `Successfully created component`,
                        //     type: "success",
                        //     isLoading: false,
                        // });

                    }

                } catch (error) {
                    if (error) {
                        // toast.update(toastId, {
                        //     ...toastConfiguration,
                        //     render: `Error while creating component.`,
                        //     type: "error",
                        //     isLoading: false,
                        // });
                    }
                }

            }


        }

        const createTableD = async () => {

            const tableD = calculateTableD();
            for (let c in tableD) {
                // const toastId = toast.loading("Creating a new component...", toastConfiguration);

                try {

                    let res = await addTorTableDMutation({
                        "variables": {
                            "torID": data?.tor?.id,
                            "componentCode": tableD[c]?.code,
                            "componentTitle": tableD[c]?.title,
                            "ects": tableD[c]?.ects,
                        }
                    });


                    if (!res?.errors) {
                        // toast.update(toastId, {
                        //     ...toastConfiguration,
                        //     render: `Successfully created component`,
                        //     type: "success",
                        //     isLoading: false,
                        // });

                    }

                } catch (error) {
                    if (error) {
                        // toast.update(toastId, {
                        //     ...toastConfiguration,
                        //     render: `Error while creating component.`,
                        //     type: "error",
                        //     isLoading: false,
                        // });
                    }
                }

            }


        }
        const createTables = async () => {

            if (createdTor && data?.tor?.id) {
                await createTableC();
                await createTableD();
                await refetchTor();
                setUpdated(false);
                setCreatedTor(false);
            }
              

        }
        createTables();
    }, [createdTor, data?.tor?.id])

    const formIsValidated = (e) => {
        setValidated(true);
        e.preventDefault();

        if (!formRef.current.checkValidity()) {
            e.stopPropagation();
            return false;
        }

        return true;
    };
    const dataToRender = (tableType) => tableType === "tableC" ? data?.tor?.field_tor_table_c?.map(
        ({
            id,
            field_tor_table_c_code,
            field_tor_table_c_title,
            field_tor_table_c_grade,
            field_tor_table_c_ects,
            field_tor_table_c_completed
        }) => ({
            id,
            componentCode: field_tor_table_c_code,
            componentTitleAtTheReceivingInstitution: field_tor_table_c_title,
            gradesReceivedAtTheReceivingInstitution: field_tor_table_c_grade,
            numberOfEctsCredits: field_tor_table_c_ects,
            successfullyCompleted: <FontAwesomeIcon
                icon={field_tor_table_c_completed ? faCheck : faXmark}
                size="2x"
                color={field_tor_table_c_completed ? COLORS.greenAccept : COLORS.redCross}
            />
        })).concat({
            componentCode: "",
            componentTitleAtTheReceivingInstitution: "",
            gradesReceivedAtTheReceivingInstitution: "Total ECTS: ",
            numberOfEctsCredits: calculateECTStotal(tableType),
            successfullyCompleted: ""
        }) : data?.tor?.field_tor_table_d?.map(
            ({
                id,
                field_tor_table_d_code,
                field_tor_table_d_title,
                field_tor_table_d_grade,
                field_tor_table_d_ects
            }) => ({
                id,
                componentCode: field_tor_table_d_code,
                componentTitleAtTheSendingInstitution: field_tor_table_d_title,
                gradesRegisteredAtTheSendingInstitution: field_tor_table_d_grade,
                numberOfEctsCreditsRecognised: field_tor_table_d_ects
            })).concat({

                componentCode: "",
                componentTitleAtTheSendingInstitution: "",
                gradesRegisteredAtTheSendingInstitution: "Total ECTS: ",
                numberOfEctsCreditsRecognised: calculateECTStotal(tableType)
            })

    const calculateECTStotal = (tableType) => {
        let totalECTS = 0;
        if (tableType === "tableC") {
            data?.tor?.field_tor_table_c?.map((c) => {
                if (Number(c?.field_tor_table_c_ects)) {
                    totalECTS += Number(c?.field_tor_table_c_ects);
                }
            });
        }
        else {
            data?.tor?.field_tor_table_d?.map((c) => {

                if (Number(c?.field_tor_table_d_ects)) {
                    totalECTS += Number(c?.field_tor_table_d_ects);
                }
            });
        }
        return totalECTS;
    }

    const updateTorTableModalBody = () => {

        return (
            <div>
                <Form validated={validated} noValidate ref={formRef}>

                    <Row style={{ marginBottom: 12, marginLeft: 25, marginRight: 25 }}>
                        <Form.Group className="mb-3" controlId="formRoleName">
                            <Form.Label>Component Code <span style={{ color: "red" }}>*</span></Form.Label>
                            <Form.Control
                                value={code}
                                required
                                onChange={(e) => setCode(e.target.value)}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please provide a code.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formRoleName">
                            <Form.Label>Component Title <span style={{ color: "red" }}>*</span></Form.Label>
                            <Form.Control
                                value={title}
                                required
                                onChange={(e) => setTitle(e.target.value)}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please provide a title.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formRoleName">
                            <Form.Label>Grade <span style={{ color: "red" }}>*</span></Form.Label>
                            <Form.Control
                                placeholder=""
                                type="number"
                                value={grade}
                                required
                                onChange={(e) => setGrade(e.target.value)}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please provide a grade.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formRoleName">
                            <Form.Label>Number of ECTS Creadits<span style={{ color: "red" }}>*</span></Form.Label>
                            <Form.Control
                                placeholder=""
                                type="number"
                                value={ects}
                                required
                                onChange={(e) => setEcts(e.target.value)}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please provide ects credits.
                            </Form.Control.Feedback>
                        </Form.Group>
                        {type === "tableC" && <Form.Check type="checkbox" id="agree-terms" >
                            <Form.Check.Input
                                type={"checkbox"}
                                className="mb-3"
                                checked={completed}
                                onClick={() => setCompleted(!completed)} />
                            <Form.Check.Label>
                                <div className="ewp-settings-checkbox-label">
                                    Successfully Completed
                                    <span style={{ color: "red" }}>*</span>
                                </div>
                            </Form.Check.Label>
                        </Form.Check>}

                    </Row>
                </Form>
            </div>
        )
    }


    const handleCreateTor = async () => {

        const toastId = toast.loading("Creating a new ToR...", toastConfiguration);

        try {
            let result
            result = await createTorMutation({
                "variables": {
                    "laID": laId,
                    "studyPeriodStart": startDate,
                    "studyPeriodEnd": endDate
                }
            });


            if (!result?.errors) {
                toast.update(toastId, {
                    ...toastConfiguration,
                    render: `Successfully created ToR`,
                    type: "success",
                    isLoading: false,
                });
                refetchTor();
                setCreatedTor(true);
            }
        }
        catch (error) {
            if (error) {
                toast.update(toastId, {
                    ...toastConfiguration,
                    render: `Error while creating ToR.`,
                    type: "error",
                    isLoading: false,
                });
            }
        }

    }

    const handleUpdateTor = async () => {

        const toastId = toast.loading("Updating a new ToR...", toastConfiguration);

        try {
            let result
            result = await updateTorMutation({
                "variables": {
                    "torID": data?.tor?.id,
                    "studyPeriodStart": editStartDate,
                    "studyPeriodEnd": editEndDate
                }
            });
            handleCloseModalUpdateteTor();
            if (!result?.errors) {
                toast.update(toastId, {
                    ...toastConfiguration,
                    render: `Successfully updated ToR`,
                    type: "success",
                    isLoading: false,
                });
                refetchTor();
                setUpdated(false);
            }
        }
        catch (error) {
            if (error) {
                toast.update(toastId, {
                    ...toastConfiguration,
                    render: `Error while updating ToR.`,
                    type: "error",
                    isLoading: false,
                });
            }
        }
    }

    const handleAdd = async (e) => {
        if (!formIsValidated(e)) return;

        const toastId = toast.loading("Creating a new Component...", toastConfiguration);
        try {
            let result
            if (type === "tableC") {
                result = await addTorTableCMutation({
                    "variables": {
                        "torID": data?.tor?.id,
                        "componentCode": code,
                        "componentTitle": title,
                        "gradeReceived": grade,
                        "ects": ects,
                        "successfullyCompleted": completed,
                    }
                });
            }
            else {
                result = await addTorTableDMutation({
                    "variables": {
                        "torID": data?.tor?.id,
                        "componentCode": code,
                        "componentTitle": title,
                        "gradeRegistered": grade,
                        "ects": ects
                    }
                });
            }
            handleCloseModalAddTor()
            if (!result?.errors) {
                toast.update(toastId, {
                    ...toastConfiguration,
                    render: `Successfully created component`,
                    type: "success",
                    isLoading: false,
                });
                refetchTor();
                setUpdated(false);
            }
        } catch (error) {
            if (error) {
                toast.update(toastId, {
                    ...toastConfiguration,
                    render: `Error while creating component.`,
                    type: "error",
                    isLoading: false,
                });
            }
        }

    }


    const handleEdit = async (e) => {
        if (!formIsValidated(e)) return;

        const toastId = toast.loading("Editing a new component...", toastConfiguration);

        try {
            let result
            if (type === "tableC") {
                result = await updateTorTableCMutation({
                    "variables": {
                        "componentID": componentId,
                        "componentCode": code,
                        "componentTitle": title,
                        "gradeReceived": String(grade),
                        "ects": ects,
                        "successfullyCompleted": completed,
                    }
                });
            }
            else {
                result = await updateTorTableDMutation({
                    "variables": {
                        "componentID": componentId,
                        "componentCode": code,
                        "componentTitle": title,
                        "gradeRegistered": String(grade),
                        "ects": ects
                    }
                });
            }

            handleCloseModalEditTor()
            if (!result?.errors) {
                toast.update(toastId, {
                    ...toastConfiguration,
                    render: `Successfully edited component`,
                    type: "success",
                    isLoading: false,
                });
                refetchTor();
                setUpdated(false);
            }
        }
        catch (error) {
            if (error) {
                toast.update(toastId, {
                    ...toastConfiguration,
                    render: `Error while editing component.`,
                    type: "error",
                    isLoading: false,
                });
            }
        }
    }

    const handleDelete = async () => {

        const toastId = toast.loading("Deleting a new component...", toastConfiguration);
        try {
            let result
            if (type === "tableC") {
                result = await deleteTorTableCMutation({
                    "variables": {
                        "torID": data?.tor?.id,
                        "componentID": componentId

                    }
                });
            }
            else {
                result = await deleteTorTableDMutation({
                    "variables": {
                        "torID": data?.tor?.id,
                        "componentID": componentId
                    }
                });
            }

            handleCloseModalDeleteTor()
            if (!result?.errors) {
                toast.update(toastId, {
                    ...toastConfiguration,
                    render: `Successfully deleted component`,
                    type: "success",
                    isLoading: false,
                });
                refetchTor();
                setUpdated(false);
            }
        } catch (error) {
            if (error) {
                toast.update(toastId, {
                    ...toastConfiguration,
                    render: `Error while deleting component.`,
                    type: "error",
                    isLoading: false,
                });
            }
        }


    }

    const calculateTableC = () => {
        const filteredTableA = tableA.filter(itemA => {
            // Check if any item in tableA2 has the same code and addedOrDeleted is 2
            return !tableA2.some(itemA2 => itemA2.code === itemA.code && itemA2.addedOrDeleted === "2");
        });
        const tableC = filteredTableA.concat(
            tableA2.filter(itemA2 => itemA2.addedOrDeleted === "1")
        );
        return tableC;
    }

    const calculateTableD = () => {
        const filteredTableB = tableB.filter(itemB => {
            // Check if any item in tableA2 has the same code and addedOrDeleted is 2
            return !tableB2.some(itemB2 => itemB2.code === itemB.code && itemB2.addedOrDeleted === "2");
        });
        const tableD = filteredTableB.concat(
            tableB2.filter(itemB2 => itemB2.addedOrDeleted === "1")
        );
        return tableD;
    }

    if (!dataTable || dataTable?.length === 0) {
        return (
            <>
                <h5 className="text-center p-4">
                    No courses to show
                </h5>
                <div className="justify-content-center d-flex">

                    <CustomButton
                        buttonType={"primary"}
                        text={"Create ToR"}
                        handleClick={() => { handleCreateTor() }}
                        icon={faPlus}
                        hasIcon
                        styles={{ marginBottom: 20 }}
                        small
                    />
                </div>
            </>
        )
    }
    if (errorTor) {
        return (
            <div className="text-center text-danger mx-auto">An error occured</div>
        );

    }
    if (loadingTor || loadingCreateTor) {
        return (
            <div
                style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%, -50%)"
                }}
            >
                <Spinner
                    as="div"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="align-middle my-auto"
                />
                <span>Loading...</span>
            </div>
        )
    }
    return (
        <div className="py-2">
            {type === "tableD" && <Row style={{ marginLeft: 5, marginRight: 5 }}>
                <Col style={{ marginTop: 20 }}>
                    <div className="col-12 pt-0 pl-3 pr-0 mx-auto mb-4">
                        <Row>
                            <Col className="col-md-6">
                                 <div>
                                    <Row style={{ paddingTop: 10, paddingBottom: 2 }}>
                                        <Col className='label-style' xs={6} sm={6} md={6} lg={6} xl={4}>Mobility Start Date:</Col>
                                        <Col xs={6} sm={6} md={6} lg={6} xl={8}>{data?.tor?.field_study_period_start}</Col>
                                    </Row>
                                    <Row style={{ paddingTop: 10, paddingBottom: 2 }}>
                                        <Col className='label-style' xs={6} sm={6} md={6} lg={6} xl={4}>Mobility End Date:</Col>
                                        <Col xs={6} sm={6} md={6} lg={6} xl={8}>{data?.tor?.field_study_period_end}</Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <Tabs defaultActiveKey="tor-table" id="tor">

                        <CustomTab eventKey="tor-table" title="TRANSCRIPT OF RECORDS AT THE RECEIVING INSTITUTION" style={{ background: "#ffff" }}>
                            <CustomTable
                                data={dataToRender("tableC")}
                                hasFilters={false}
                                hasPagination={false}
                                loading={false}
                                type={`requests-`}
                                columnsPerFieldMap={{
                                    componentCode: "col-2",
                                    componentTitleAtTheReceivingInstitution: "col-3",
                                    gradesReceivedAtTheReceivingInstitution: "col-3",
                                    numberOfEctsCredits: "col-2",
                                    successfullyCompleted: "col-2"

                                }}
                                rowClassName="tor-table-row"
                                headerClassName="tor-table-header"
                            />
                        </CustomTab>
                    </Tabs>
                    <hr className="mt-4" />
                </Col>
            </Row>}
            <div className="col-12 pt-0 pl-3 pr-0 mx-auto">
                <div className="mx-1 mx-xxl-5">
                    <Row style={{ paddingTop: 10 }}>
                        <Col className="col-md-6">
                            {type === "tableC" && (
                                <div>
                                    <Row style={{ paddingTop: 10, paddingBottom: 2 }}>
                                        <Col className='label-style' xs={6} sm={6} md={6} lg={6} xl={4}>Mobility Start Date:</Col>
                                        <Col xs={6} sm={6} md={6} lg={6} xl={8}>{data?.tor?.field_study_period_start}</Col>
                                    </Row>
                                    <Row style={{ paddingTop: 10, paddingBottom: 2 }}>
                                        <Col className='label-style' xs={6} sm={6} md={6} lg={6} xl={4}>Mobility End Date:</Col>
                                        <Col xs={6} sm={6} md={6} lg={6} xl={8}>{data?.tor?.field_study_period_end}</Col>
                                    </Row>

                                    <div style={{ paddingTop: 10, paddingBottom: 2 }}>

                                        <CustomButton
                                            buttonType={"orange"}
                                            text={"Update ToR Mobility Date"}
                                            handleClick={() => { setShowUpdateTor(true) }}
                                            icon={faPen}
                                            hasIcon
                                            styles={{ marginBottom: 20 }}
                                            small
                                        />
                                    </div>
                                </div>
                            )}
                        </Col>
                        <Col className="col-md-6 d-flex justify-content-end align-items-center pb-2">
                            <CustomButton
                                buttonType={"primary"}
                                text={"add a new component"}
                                handleClick={() => { setShowAdd(true); setCurrentIdx(null); setValidated(false); }}
                                icon={faPlus}
                                hasIcon
                                styles={{ marginLeft: 20 }}
                                small
                            />
                        </Col>
                    </Row>
                </div>
            </div>
            <Row style={{ marginLeft: 5, marginRight: 5 }}>
                <Col style={{ marginTop: 5, marginBottom: 20 }}>
                    <Tabs defaultActiveKey="tor-table" id="tor">
                        {updated &&
                            <CustomTab eventKey="tor-table" title={type === "tableD" ? "RECOGNITION AT THE SENDING INSTITUTION" : "TRANSCRIPT OF RECORDS AT THE RECEIVING INSTITUTION"} style={{ background: "#ffff" }}>
                                <CustomTable
                                    data={dataTable}
                                    hasFilters={false}
                                    hasPagination={false}
                                    loading={false}
                                    type={`requests-`}
                                    columnsPerFieldMap={type === "tableC" ? {
                                        componentCode: "col-2",
                                        componentTitleAtTheReceivingInstitution: "col-3",
                                        gradesReceivedAtTheReceivingInstitution: "col-3",
                                        numberOfEctsCredits: "col-2",
                                        successfullyCompleted: "col-2"

                                    } : {
                                        componentCode: "col-2",
                                        componentTitleAtTheSendingInstitution: "col-3",
                                        gradesRegisteredAtTheSendingInstitution: "col-3",
                                        numberOfEctsCreditsRecognised: "col-3",

                                    }}
                                    rowClassName="tor-table-row"
                                    headerClassName="tor-table-header"
                                >
                                    {dataTable?.map((r, idx) => (
                                        <div
                                            key={r?.nid}
                                            className={`py-3 px-2 justify-content-end justify-content-lg-around align-items-center bg-none bg-md-light ${(idx === dataTable.length - 1) ? "" : "button-triplet-requests"} flex-grow-1 d-flex gap-3 gap-lg-1`}
                                        >
                                            {(idx !== dataTable.length - 1) && <><IconButton
                                                variant="edit"
                                                title="Edit Component"
                                                onClick={() => { setShowEdit(true); setCurrentIdx(idx); setValidated(false); }}
                                            />
                                                <IconButton
                                                    variant="delete"
                                                    title="Delete Component"
                                                    onClick={() => { setShowDelete(true); setCurrentIdx(idx); }}
                                                />
                                            </>
                                            }
                                        </div>
                                    ))}
                                </CustomTable></CustomTab>}
                    </Tabs>
                </Col>
            </Row>

            <CustomModal show={showAdd} handleClose={handleCloseModalAddTor}
                fullscreen={false}
                body={updateTorTableModalBody()}
                button={
                    <CustomButton
                        buttonType={"accept"}
                        disabled={loadingAddTorTableC || loadingAddTorTableD}
                        text={"Submit"}
                        icon={faFloppyDisk}
                        hasIcon={true}
                        iconColor={COLORS.white}
                        handleClick={handleAdd}
                    />

                }
                title={<div>Add a new <span style={{ color: "#3D619C" }}>Component</span></div>}
            />
            <CustomModal show={showEdit} handleClose={handleCloseModalEditTor}
                fullscreen={false}
                body={updateTorTableModalBody()}
                button={
                    <CustomButton
                        buttonType={"accept"}
                        disabled={loadingUpdateTorTableC || loadingUpdateTorTableD}
                        text={"Submit"}
                        icon={faFloppyDisk}
                        hasIcon={true}
                        iconColor={COLORS.white}
                        handleClick={handleEdit}
                    />

                }
                title={<div>Edit <span style={{ color: "#3D619C" }}>Component</span></div>} />
            <CustomModal show={showDelete} handleClose={handleCloseModalDeleteTor}
                fullscreen={false}
                body={<div>Are you sure?</div>}
                button={
                    <CustomButton
                        buttonType={"delete"}
                        disabled={loadingDeleteTorTableC || loadingDeleteTorTableD}
                        text={"Delete"}
                        icon={faTrash}
                        hasIcon={true}
                        iconColor={COLORS.white}
                        handleClick={handleDelete}
                    />

                }
                title={<div>Delete <span style={{ color: "#3D619C" }}>Component</span></div>} />
            <CustomModal show={showUpdateTor} handleClose={handleCloseModalUpdateteTor}
                fullscreen={false}
                body={
                    <>
                        <Col lg={4}>
                            <Form.Group className="col-12" controlId="formBasicEmail">
                                <Form.Label>Mobility Start Date <span style={{ color: "red" }}>*</span></Form.Label>

                                <DatePicker selected={new Date(editStartDate)}
                                    dateFormat="dd/MM/yyyy"
                                    onChange={(date) => setEditStartDate(date.toLocaleDateString('en-CA', { day: 'numeric', month: 'numeric', year: 'numeric' }))} className="form-control" popperPlacement='bottom' />

                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid value prompt.
                                </Form.Control.Feedback>
                            </Form.Group>

                        </Col>

                        <Col lg={4}>
                            <Form.Group className="col-12" controlId="formBasicEmail">
                                <Form.Label>Mobility End Date <span style={{ color: "red" }}>*</span></Form.Label>
                                <DatePicker selected={new Date(editEndDate)}
                                    dateFormat="dd/MM/yyyy"
                                    onChange={(date) => setEditEndDate(date.toLocaleDateString('en-CA', { day: 'numeric', month: 'numeric', year: 'numeric' }))} className="form-control" popperPlacement='bottom' />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid value prompt.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </>
                }
                button={
                    <CustomButton
                        buttonType={"accept"}
                        disabled={loadingUpdateTor}
                        text={"Update"}
                        icon={faCheck}
                        hasIcon={true}
                        iconColor={COLORS.white}
                        handleClick={handleUpdateTor}
                    />

                }
                title={<div>Update <span style={{ color: "#3D619C" }}>ToR</span></div>} />

        </div>
    )
}

export default TorTable;